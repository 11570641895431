import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Inicio = () => import('@/views/pages/Home')
const Login = () => import('@/views/pages/Login')
// const Register = () => import('@/views/pages/Register')
const ResetPassword = () => import('@/views/pages/Reset')
const ForgotPassword = () => import('@/views/pages/Forgot')
const Register = () => import('@/views/pages/Reset')

// const Cargos = () => import('@/views/cargos/List')
// const CargoAdd = () => import('@/views/cargos/ABM')
// const CargoEdit = () => import('@/views/cargos/ABM')
// const CargoDatos = () => import('@/views/cargos/Data')
const Carreras = () => import('@/views/carreras/List')
const CarreraAdd = () => import('@/views/carreras/ABM')
const CarreraEdit = () => import('@/views/carreras/ABM')
const CarreraDatos = () => import('@/views/carreras/Data')
const Facultades = () => import('@/views/facultades/List')
const FacultadAdd = () => import('@/views/facultades/ABM')
const FacultadEdit = () => import('@/views/facultades/ABM')
const FacultadDatos = () => import('@/views/facultades/Data')
const Personal = () => import('@/views/personal/List')
const PersonalAdd = () => import('@/views/personal/ABM')
const PersonalEdit = () => import('@/views/personal/ABM')
const PersonalDatos = () => import('@/views/personal/Data')
const PersonalContrataciones = () => import('@/views/personalContrataciones/List')
const PersonalContratacionAdd = () => import('@/views/personalContrataciones/ABM')
const PersonalContratacionEdit = () => import('@/views/personalContrataciones/ABM')
const PersonalContratacionDatos = () => import('@/views/personalContrataciones/Data')
const PersonalDocumentos = () => import('@/views/personalDocumentos/List')
const PersonalDocumentoAdd = () => import('@/views/personalDocumentos/ABM')
const PresupuestoPersonalDocumentos = () => import('@/views/personalDocumentos/List')
const PresupuestoPersonalDocumentoAdd = () => import('@/views/personalDocumentos/ABM')
const PresupuestoPersonalDocumentosFaltantes = () => import('@/views/personalDocumentos/DocumentosFaltantes')
const Presupuestos = () => import('@/views/presupuestos/List')
const PresupuestoAdd = () => import('@/views/presupuestos/ABM')
const PresupuestoEdit = () => import('@/views/presupuestos/ABM')
const PresupuestoDatos = () => import('@/views/presupuestos/Data')
const PresupuestoDuplicar = () => import('@/views/presupuestos/ABM')
const PresupuestosRpt = () => import('@/views/presupuestos/Rpt')
const Rubros = () => import('@/views/rubros/List')
const RubroAdd = () => import('@/views/rubros/ABM')
const RubroEdit = () => import('@/views/rubros/ABM')
const RubroDatos = () => import('@/views/rubros/Data')
const RubrosConceptos = () => import('@/views/rubrosConceptos/RubrosConceptos')

const Documentos = () => import('@/views/documentos/List')

const Dashboard = () => import('@/views/Dashboard')
const Usuarios = () => import('@/views/usuarios/List')
const UsuarioEdit = () => import('@/views/usuarios/ABM')
const UsuarioDatos = () => import('@/views/usuarios/Data')
const UsuarioFacultades = () => import('@/views/usuarios/Facultades')
const Perfil = () => import('@/views/usuarios/Perfil')
const Pass = () => import('@/views/usuarios/Pass')

Vue.use(Router)

function requireAuth (to, from, next) {
  let loggedIn = (/true/i).test(store.getters['user/loggedIn']);

  if (loggedIn) {
    if ( !store.getters['user/tieneAcceso'](to) ) {
      /* Acceso denegado */
      next ('/');
    } else {
      /* Acceso habilitado */
      next ();
    }
  } else {
    store.dispatch('user/tryLogin')
      .then(() => {
        loggedIn = (/true/i).test(store.getters['user/loggedIn']);

        if (loggedIn) {
          if ( !store.getters['user/tieneAcceso'](to) ) {
            /* Acceso denegado */
            next ('/');
          } else {
            /* Acceso habilitado */
            next ();
          }
        } else {
          next ('/login')
        }
      })
      .catch(() => next ('/login'));
  }
}

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      meta: { label: 'UCCuyo'},
      component: DefaultContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          // meta: { label: 'Inicio'},
          component: Dashboard,
          beforeEnter: requireAuth,
        },

        /* Presupuestos */
        {
          path: 'presupuestos',
          meta: { label: 'Presupuestos'},
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'Presupuestos',
              meta: { label: 'Listado de Presupuestos'},
              component: Presupuestos,
              beforeEnter: requireAuth,
            },
            {
              path: 'add',
              meta: { label: 'Información del Presupuesto'},
              name: 'PresupuestoAdd',
              component: PresupuestoAdd,
              beforeEnter: requireAuth,
            },
            {
              path: ':id/edit',
              meta: { label: 'Información del Presupuesto'},
              name: 'PresupuestoEdit',
              component: PresupuestoEdit,
              beforeEnter: requireAuth,
            },
            {
              path: ':id/duplicar',
              meta: { label: 'Información del Presupuesto'},
              name: 'PresupuestoDuplicar',
              component: PresupuestoDuplicar,
              beforeEnter: requireAuth,
            },
            {
              path: ':id',
              meta: { label: 'Informacion del Presupuesto'},
              name: 'PresupuestoDatos',
              component: PresupuestoDatos,
              beforeEnter: requireAuth,
            },
            {
              path: ':presupuestoId/contrataciones',
              meta: { label: 'Contrataciones'},
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  name: 'PersonalContrataciones',
                  meta: { label: 'Listado de Contrataciones'},
                  component: PersonalContrataciones,
                  beforeEnter: requireAuth,
                },
                {
                  path: 'add',
                  meta: { label: 'Informacion de la Contratación'},
                  name: 'PersonalContratacionAdd',
                  component: PersonalContratacionAdd,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id/edit',
                  meta: { label: 'Informacion de la Contratación'},
                  name: 'PersonalContratacionEdit',
                  component: PersonalContratacionEdit,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id',
                  meta: { label: 'Informacion de la Contratación'},
                  name: 'PersonalContratacionDatos',
                  component: PersonalContratacionDatos,
                  beforeEnter: requireAuth,
                },
              ]
            },
            {
              path: ':presupuestoId/documentos',
              meta: { label: 'Documentos'},
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  name: 'PresupuestoPersonalDocumentos',
                  meta: { label: 'Listado de Documentos'},
                  component: PresupuestoPersonalDocumentos,
                  beforeEnter: requireAuth,
                },
                {
                  path: 'add',
                  meta: { label: 'Informacion del Documento'},
                  name: 'PresupuestoPersonalDocumentoAdd',
                  component: PresupuestoPersonalDocumentoAdd,
                  beforeEnter: requireAuth
                },
                {
                  path: 'faltantes',
                  meta: { label: 'Listado de Documentos Faltantes'},
                  name: 'PresupuestoPersonalDocumentosFaltantes',
                  component: PresupuestoPersonalDocumentosFaltantes,
                  beforeEnter: requireAuth
                },
              ]
            },
          ]
        },
        
        /* Personal */
        {
          path: 'rr-hh',
          meta: { label: 'RRHH'},
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: 'personal',
              meta: { label: 'Personal'},
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  name: 'Personal',
                  meta: { label: 'Listado de Personal'},
                  component: Personal,
                  beforeEnter: requireAuth,
                },
                {
                  path: 'add',
                  meta: { label: 'Informacion del Personal'},
                  name: 'PersonalAdd',
                  component: PersonalAdd,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id/edit',
                  meta: { label: 'Informacion del Personal'},
                  name: 'PersonalEdit',
                  component: PersonalEdit,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id',
                  meta: { label: 'Informacion del Personal'},
                  name: 'PersonalDatos',
                  component: PersonalDatos,
                  beforeEnter: requireAuth,
                },
              ]
            },
            {
              path: 'documentos',
              meta: { label: 'Documentos'},
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  name: 'PersonalDocumentos',
                  meta: { label: 'Listado de Documentos'},
                  component: PersonalDocumentos,
                  beforeEnter: requireAuth,
                },
                {
                  path: 'add',
                  meta: { label: 'Informacion del Documento'},
                  name: 'PersonalDocumentoAdd',
                  component: PersonalDocumentoAdd,
                  beforeEnter: requireAuth
                },
              ]
            },
          ]
        },

        /* Tablas */
        {
          path: 'tablas',
          meta: { label: 'Tablas'},
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireAuth,
          children: [
            // {
            //   path: 'cargos',
            //   meta: { label: 'Cargos'},
            //   component: {
            //     render (c) { return c('router-view') }
            //   },
            //   children: [
            //     {
            //       path: '',
            //       name: 'Cargos',
            //       meta: { label: 'Listado de Cargos'},
            //       component: Cargos,
            //       beforeEnter: requireAuth,
            //     },
            //     {
            //       path: 'add',
            //       meta: { label: 'Informacion del Cargo'},
            //       name: 'CargoAdd',
            //       component: CargoAdd,
            //       beforeEnter: requireAuth,
            //     },
            //     {
            //       path: ':id/edit',
            //       meta: { label: 'Informacion del Cargo'},
            //       name: 'CargoEdit',
            //       component: CargoEdit,
            //       beforeEnter: requireAuth,
            //     },
            //     {
            //       path: ':id',
            //       meta: { label: 'Informacion del Cargo'},
            //       name: 'CargoDatos',
            //       component: CargoDatos,
            //       beforeEnter: requireAuth,
            //     },
            //   ]
            // },
            {
              path: 'rubros',
              meta: { label: 'Rubros'},
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  name: 'RubrosConceptos',
                  meta: { label: 'Rubros - Conceptos'},
                  component: RubrosConceptos,
                  beforeEnter: requireAuth,
                }
              ]
            },
            {
              path: 'carreras',
              meta: { label: 'Carreras'},
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  name: 'Carreras',
                  meta: { label: 'Listado de Carreras/Cursos'},
                  component: Carreras,
                  beforeEnter: requireAuth,
                },
                {
                  path: 'add',
                  meta: { label: 'Informacion de la Carrera/Curso'},
                  name: 'CarreraAdd',
                  component: CarreraAdd,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id/edit',
                  meta: { label: 'Informacion de la Carrera/Curso'},
                  name: 'CarreraEdit',
                  component: CarreraEdit,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id',
                  meta: { label: 'Informacion de la Carrera/Curso'},
                  name: 'CarreraDatos',
                  component: CarreraDatos,
                  beforeEnter: requireAuth,
                },
              ]
            },

            {
              path: 'facultades',
              meta: { label: 'Facultades'},
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  name: 'Facultades',
                  meta: { label: 'Listado de Facultades'},
                  component: Facultades,
                  beforeEnter: requireAuth,
                },
                {
                  path: 'add',
                  meta: { label: 'Informacion de la Facultad'},
                  name: 'FacultadAdd',
                  component: FacultadAdd,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id/edit',
                  meta: { label: 'Informacion de la Facultad'},
                  name: 'FacultadEdit',
                  component: FacultadEdit,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id',
                  meta: { label: 'Informacion de la Facultad'},
                  name: 'FacultadDatos',
                  component: FacultadDatos,
                  beforeEnter: requireAuth,
                },
              ]
            },
            // {
            //   path: 'documentos',
            //   meta: { label: 'Documentos'},
            //   component: {
            //     render (c) { return c('router-view') }
            //   },
            //   children: [
            //     {
            //       path: '',
            //       name: 'Documentos',
            //       meta: { label: 'Listado de Documentos'},
            //       component: Documentos,
            //       beforeEnter: requireAuth,
            //     }
            //   ]
            // },
          ]
        },

        /* Reportes */
        {
          path: 'reportes',
          meta: { label: 'Reportes'},
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: 'presupuestos',
              meta: { label: 'Reporte de Presupuestos'},
              component: PresupuestosRpt,
              beforeEnter: requireAuth,
            }
          ]
        },

        /* Seguridad */
        {
            path: 'seguridad',
            meta: { label: 'Seguridad'},
            component: {
              render (c) { return c('router-view') }
            },
            beforeEnter: requireAuth,
            children: [
              /* Usuarios */
              {
                path: 'usuarios',
                meta: { label: 'Usuarios'},
                component: {
                  render (c) { return c('router-view') }
                },
                beforeEnter: requireAuth,
                children: [
                  {
                    path: '',
                    name: 'Usuarios',
                    meta: { label: 'Listado de Usuarios'},
                    component: Usuarios,
                    beforeEnter: requireAuth,
                  },
                  {
                    path: ':id',
                    meta: { label: 'Informacion del Usuario'},
                    name: 'UsuarioDatos',
                    component: UsuarioDatos,
                    beforeEnter: requireAuth,
                  },
                  {
                    path: ':id/edit',
                    meta: { label: 'Información del Usuario'},
                    name: 'UsuarioEdit',
                    component: UsuarioEdit,
                    beforeEnter: requireAuth,
                  },
                  {
                    path: ':id/facultades',
                    meta: { label: 'Información del Usuario'},
                    name: 'UsuarioFacultades',
                    component: UsuarioFacultades,
                    beforeEnter: requireAuth,
                  },
                ]
              }
            ]
        },
        {
          path: '/perfil',
          name: 'Perfil',
          component: Perfil,
          beforeEnter: requireAuth,
        },    
        {
          path: '/pwd',
          name: 'Pass',
          meta: { label: 'Actualización de la Contraseña'},
          component: Pass,
          beforeEnter: requireAuth,
        },    
   
      ]
    },

    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/reset-pwd',
      name: 'ResetPassword',
      component: ResetPassword,
      props: { register: false }
    },
    {
      path: '/registro',
      name: 'Register',
      component: Register,
      props: { register: true }
    },
    {
      path: '/forgot',
      name: 'ForgotPassword',
      component: ForgotPassword
    },    
  ]
})

export default router;