import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';
import moment  from 'moment';

const state = {
    id: 0,
    facultad: '',
    carrera: '',
    tipoCarrera: '',
    periodo: 0,
    fechaDesde: '1900-01-01',
    fechaHasta: '1900-01-01',
    // numeroRevision: 0,
    pjeInflacionEstimada: 0,
    observaciones: '',
    fechaCierre: '1900-01-01',
    usuarioCierre: '',
    cerrado: false,

    renglones: []
};

const mutations = {
    updateField,

    'INICIALIZAR_DATOS'(state, presupuestoId) {
        state.id = presupuestoId;
        state.facultad = '';
        state.carrera = '';
        state.tipoCarrera = '';
        state.periodo = '';
        state.fechaDesde = '1900-01-01';
        state.fechaHasta = '1900-01-01';
        // state.numeroRevision = '';
        state.pjeInflacionEstimada = 0;
        state.observaciones = '';
        state.fechaCierre = '1900-01-01';
        state.usuarioCierre = '';
        state.cerrado = false;
    
        state.renglones = [];  
    },
    'SET_DATOS'(state, presupuesto) {
        state.facultad = presupuesto.facultad;
        state.carrera = presupuesto.carrera;
        state.tipoCarrera = presupuesto.tipoCarrera;
        state.periodo = presupuesto.periodo;
        state.fechaDesde = moment(presupuesto.fechaDesde).format('YYYY-MM-DD');
        state.fechaHasta = moment(presupuesto.fechaHasta).format('YYYY-MM-DD');
        // state.numeroRevision = presupuesto.numeroRevision;
        state.pjeInflacionEstimada = presupuesto.pjeInflacionEstimada;
        state.observaciones = presupuesto.observaciones;
        state.fechaCierre = moment(presupuesto.fechaCierre).format('YYYY-MM-DD');
        state.usuarioCierre = presupuesto.usuarioCierre;
        state.cerrado = (/true/i).test(presupuesto.cerrado);
        
        state.renglones = getRenglones(presupuesto.renglones);
        calcularTotales(state);
    },
};

function getRenglones (renglones) {
    let renglonesPresupuesto = [];

    if (renglones) {
        renglones.sort(function(a, b){
            if (a.rubro < b.rubro) { 
                return -1; 
            }
            if (a.rubro > b.rubro) {
                return 1;
            }
            if (a.concepto < b.concepto) { 
                return -1; 
            }
            if (a.concepto > b.concepto) {
                return 1;
            }
            return 0;
        }).forEach(renglon => {
            let rubroPresupuesto = renglonesPresupuesto.find(t => t.id == renglon.rubroId);
            if (rubroPresupuesto) {
                let conceptoPresupuesto = rubroPresupuesto.conceptos.find(t => t.id == renglon.conceptoId);
                if (conceptoPresupuesto) {
                    /* Agregamos el Item */
                    let item = {
                        id: renglon.id,
                        rubroId: renglon.rubroId,
                        conceptoId: renglon.conceptoId,
                        personalId: renglon.personalId,
                        personal: renglon.personal,
                        titulo: renglon.titulo,
                        subcantidad: renglon.subcantidad,
                        cantidad: renglon.cantidad,
                        precioUnitario: renglon.precioUnitario,
                        subtotal: parseFloat(renglon.cantidad) * parseFloat(renglon.precioUnitario),
                        observaciones: renglon.observaciones,
                        _showDetails: true
                    }

                    if ((/true/i).test(conceptoPresupuesto.ingresoCuotas) || (/true/i).test(conceptoPresupuesto.honorariosHoras)) {
                        item.subtotal = parseFloat(renglon.subcantidad)
                            * parseFloat(renglon.cantidad)
                            * parseFloat(renglon.precioUnitario);
                    }

                    conceptoPresupuesto.items.push(item);
                } else {
                    /* Agregamos el Concepto + Item */
                    let concepto = {
                        id: renglon.conceptoId,
                        rubroId: renglon.rubroId,
                        nombre: renglon.concepto,
                        honorarios: renglon.honorarios,
                        honorariosHoras: renglon.honorariosHoras,
                        ingresoCuotas: renglon.ingresoCuotas,
                        total: 0,
                        items: [],
                        showItems: false
                    }
                    let item = {
                        id: renglon.id,
                        rubroId: renglon.rubroId,
                        conceptoId: renglon.conceptoId,
                        personalId: renglon.personalId,
                        personal: renglon.personal,
                        titulo: renglon.titulo,
                        subcantidad: renglon.subcantidad,
                        cantidad: renglon.cantidad,
                        precioUnitario: renglon.precioUnitario,
                        subtotal: parseFloat(renglon.cantidad) * parseFloat(renglon.precioUnitario),
                        observaciones: renglon.observaciones,
                        _showDetails: true
                    }
                    if ((/true/i).test(concepto.ingresoCuotas) || (/true/i).test(concepto.honorariosHoras)) {
                        item.subtotal = parseFloat(renglon.subcantidad)
                            * parseFloat(renglon.cantidad)
                            * parseFloat(renglon.precioUnitario);
                    }

                    concepto.items.push(item);
                    rubroPresupuesto.conceptos.push(concepto);
                }
            } else {
                /* Agregamos el Rubro + Concepto + Item */
                let rubro = {
                    id: renglon.rubroId,
                    nombre: renglon.rubro.trim(),
                    ingresos: renglon.rubroIngresos,
                    total: 0,
                    conceptos: [],
                    showConceptos: false
                }
                let concepto = {
                    id: renglon.conceptoId,
                    rubroId: renglon.rubroId,
                    nombre: renglon.concepto,
                    honorarios: renglon.honorarios,
                    honorariosHoras: renglon.honorariosHoras,
                    ingresoCuotas: renglon.ingresoCuotas,
                    total: 0,
                    items: [],
                    showItems: false
                }
                let item = {
                    id: renglon.id,
                    rubroId: renglon.rubroId,
                    conceptoId: renglon.conceptoId,
                    personalId: renglon.personalId,
                    personal: renglon.personal,
                    titulo: renglon.titulo,
                    subcantidad: renglon.subcantidad,
                    cantidad: renglon.cantidad,
                    precioUnitario: renglon.precioUnitario,
                    subtotal: parseFloat(renglon.cantidad) * parseFloat(renglon.precioUnitario),
                    observaciones: renglon.observaciones,
                    _showDetails: true
                }
                if ((/true/i).test(concepto.ingresoCuotas) || (/true/i).test(concepto.honorariosHoras)) {
                    item.subtotal = parseFloat(renglon.subcantidad)
                        * parseFloat(renglon.cantidad)
                        * parseFloat(renglon.precioUnitario);
                }
                
                concepto.items.push(item);
                rubro.conceptos.push(concepto);
                renglonesPresupuesto.push(rubro);
            }
        });
    }
    return renglonesPresupuesto;
}
function calcularTotales(state) {
    state.renglones.forEach(rubro => {
        rubro.conceptos.forEach(concepto => {
            concepto.total = 0;
            if (concepto.items) {
                concepto.total = concepto.items.reduce((total, item) => total + parseFloat(item.subtotal), 0);
            }
        });

        rubro.total = rubro.conceptos.reduce((total, concepto) => total + parseFloat(concepto.total), 0);
    });
}

const actions = {
    getDatos ( { commit }, presupuestoId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DATOS', presupuestoId);

            if (presupuestoId) {
                apiAxios.get('presupuestos/' + presupuestoId ) 
                    .then(res => {
                        commit('SET_DATOS', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })
    },
    getDatosIngresos ( { commit }, presupuestoId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DATOS', presupuestoId);

            if (presupuestoId) {
                apiAxios.get(`presupuestos/${presupuestoId}/ingresos`) 
                    .then(res => {
                        commit('SET_DATOS', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })
    },
};

const getters = {
    getField,

    presupuesto(state) {
        return {
            id: state.id,
            facultad: state.facultad,
            carrera: state.carrera,
            tipoCarrera: state.tipoCarrera,
            periodo: state.periodo,
            fechaDesde: state.fechaDesde,
            fechaHasta: state.fechaHasta,
            numeroRevision: state.numeroRevision,
            pjeInflacionEstimada: state.pjeInflacionEstimada,
            observaciones: state.observaciones,
            renglones: state.renglones
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}