import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    codigo: 0,
    rubroId: 0,
    nombre: '',
    honorarios: false,
    honorariosHoras: false,
    ingresoCuotas: false,
    inactivo: false,
    rowVersion: null,

    cuentas: []
};

const mutations = {
    updateField,

    'INICIALIZAR'(state, params) {
        state.id = params.conceptoId;
        state.codigo = 0;
        state.rubroId = params.rubroId;
        state.nombre = '';
        state.honorarios = false;
        state.honorariosHoras = false;
        state.ingresoCuotas = false;
        state.inactivo = false;
        state.rowVersion = null;
        state.cuentas = [];
    },
    'SET_CONCEPTO'(state, concepto) {
        state.codigo = parseInt(concepto.codigo);
        state.nombre = concepto.nombre.trim();
        state.honorarios = (/true/i).test(concepto.honorarios);
        state.honorariosHoras = (/true/i).test(concepto.honorariosHoras);
        state.ingresoCuotas = (/true/i).test(concepto.ingresoCuotas);
        state.inactivo = (/true/i).test(concepto.inactivo);
        state.rowVersion = concepto.rowVersion;
        state.cuentas = concepto.conceptoCuentas;
    },
    'ADD_CUENTA' (state, cuenta) {
        let itemKey = state.cuentas
            .findIndex(t => t.cuentaId == cuenta.id);
        
        if (itemKey == -1) {
            state.cuentas.push({
                cuentaId: cuenta.id,
                codigo: cuenta.codigo,
                nombre: cuenta.nombre
            })
        } else {
            throw new Error('La cuenta ya se encuentra incluida en la lista de cuentas asociadas');
        }
    },
    'REMOVE_CUENTA' (state, cuentaId) {
        let itemKey = state.cuentas
            .findIndex(t => t.cuentaId == cuentaId);

        if (itemKey != -1) {
            state.cuentas.splice(itemKey, 1);
        }

    }
};

const actions = {
    inicializar: ( { commit }, params )  => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', params);

            let conceptoId = 0;
            if (params.conceptoId) {
                conceptoId = params.conceptoId | 0;
            }

            if (conceptoId != 0) {
                apiAxios.get('conceptos/' + conceptoId + '/abm')
                    .then(res => {
                        commit('SET_CONCEPTO', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })            
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let error = '';
            if (save) {
                let honorariosHoras = (/true/i).test(state.honorarios) ? (/true/i).test(state.honorariosHoras) : false;

                let concepto= {
                    codigo: parseInt(state.codigo),
                    rubroId: state.rubroId,
                    nombre: state.nombre.trim().toUpperCase(),
                    honorarios: (/true/i).test(state.honorarios),
                    honorariosHoras,
                    ingresoCuotas: (/true/i).test(state.ingresoCuotas),
                    inactivo: (/true/i).test(state.inactivo),
                    conceptoCuentas: state.cuentas.map(cuenta => ({
                        cuentaId: cuenta.cuentaId
                    }))
                }

                let url = 'conceptos';
                if (state.id != 0) {
                    url += '/' + state.id;
                    concepto.rowVersion = state.rowVersion;
                }
                apiAxios.post(url, concepto)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(error);
            }
        })
    },
    addCuenta: ( { commit }, cuenta) => {
        return new Promise((resolve, reject) => {
            try {
                commit('ADD_CUENTA', cuenta);
                resolve();
            } catch (error) {
                reject(error);                
            }
        })
    },
    removeCuenta: ( { commit }, cuentaId) => {
        commit('REMOVE_CUENTA', cuentaId);
    },
};

const getters = {
    getField,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}