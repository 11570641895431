import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    presupuestoId: 0,
    carreraId: 0,
    carrera: null,
    periodo: '',

    personalContrataciones: [],
    /* Paginacion */
    paginaActual: 1,
    registrosPorPagina: 10,
    totalRegistros: 0,
    totalPaginas: 0,
    personal: null,
    cargo: null,
    carrera: null,
    periodoDesde: '2021',
    periodoHasta: '2024',
    incluirBorrados: false,

    list: [],
    selectList: [],
};

const mutations = {
    updateField,

    'INICIALIZAR'(state, presupuestoId) {
        state.presupuestoId = presupuestoId;
        state.carreraId = 0;
        state.carrera = null;
        state.periodo = null;
    
        state.personalContrataciones = [];
    },
    'SET_PRESUPUESTO'(state, presupuesto) {
        state.carreraId = presupuesto.carreraId;
        state.carrera = presupuesto.carrera;
        state.periodo = presupuesto.periodo.toString();
    },
    'SET_PERSONAL_CONTRATACIONES'(state, personalContrataciones) {
        state.personalContrataciones = personalContrataciones;
    },
    'SET_PAGINATION'(state, params) {
        state.paginaActual = params.paginaActual;
        state.registrosPorPagina = params.registrosPorPagina;
        state.totalRegistros = params.totalRegistros;
        state.totalPaginas = params.totalPaginas;
    },

    'INICIALIZAR_LIST'(state) {
        state.list = [];
        state.selectList = [];
    },
    'SET_LIST'(state, personalContrataciones) {
        state.list = personalContrataciones;
        state.selectList = personalContrataciones.map(personalContratacion => personalContratacion.personal.trim());
    },
};

const actions = {
    inicializar ( { commit }, presupuestoId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', presupuestoId);

            apiAxios.get('presupuestos/' + presupuestoId) 
                .then(res => {
                    commit('SET_PRESUPUESTO', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    getPersonalContrataciones: ({ commit }) => {
        return new Promise((resolve, reject) => {
            // commit('INICIALIZAR');

            let queryString = '?pageSize=' + state.registrosPorPagina
                + '&pageNumber=' + state.paginaActual
                + '&carreraId=' + state.carreraId
                + '&periodo=' + state.periodo;


            if (state.personal) {
                if (state.personal.id != 0) {
                    queryString += '&personalId=' + state.personal.id;
                }
            }
            
            if (state.periodoDesde) {
                if (state.periodoDesde != 0) {
                    queryString += '&periodoDesde=' + state.periodoDesde;
                }
            }
            if (state.periodoHasta) {
                if (state.periodoHasta != 0) {
                    queryString += '&periodoHasta=' + state.periodoHasta;
                }
            }
            if ((/true/i).test(state.incluirBorrados)) {
                queryString += '&incluirBorrados=true';
            }

            apiAxios.get('personal-contrataciones' + queryString)
                .then(res => {
                    if (res.headers.pagination){
                        const pagination= JSON.parse(res.headers.pagination)
                        var params = {
                            paginaActual: pagination.currentPage,
                            registrosPorPagina: pagination.itemsPerPage,
                            totalRegistros: pagination.totalItems,
                            totalPaginas: pagination.totalPages,
                        }    
                        commit('SET_PAGINATION', params);
                    }
                    commit('SET_PERSONAL_CONTRATACIONES', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
    getList: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_LIST');

            apiAxios.get('personal-contrataciones/list')
                .then(res => {
                    commit('SET_LIST', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
    deletePersonalContratacion ( {}, personalContratacionId ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('personal-contrataciones/' + personalContratacionId + '/delete')
                .then(() => resolve())
				.catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
};

const getters = {
    getField,

    list (state) {
        return state.list;
    },
    selectList (state) {
        return state.selectList;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}