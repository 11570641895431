import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import moment from 'moment';
import helpers from '../../mixins/helpers';
import presupuesto from './presupuesto';

const state = {
    presupuestos: [],
    /* Paginacion */
    paginaActual: 1,
    registrosPorPagina: 10,
    totalRegistros: 0,
    totalPaginas: 0,
    facultad: null,
    carrera: null,
    periodoDesde: (new Date().getFullYear()).toString(),
    periodoHasta: (new Date().getFullYear()).toString(),
    incluirBorrados: false,
    // incluirRevisiones: false

    /* Totales */
    paginaPresupuestos: 0,
    paginaIngresos: 0,
    paginaEgresos: 0,
    paginaIngresosEjecutados: 0,
    paginaEgresosEjecutados: 0,

    totalPresupuestos: 0,
    totalIngresos: 0,
    totalEgresos: 0,
    totalIngresosEjecutados: 0,
    totalEgresosEjecutados: 0,

    rptPresupuestos: [],
    list: [],
    selectList: []
};

const mutations = {
    updateField,

    'INICIALIZAR'(state) {
        state.presupuestos = [];

        state.paginaPresupuestos = 0;
        state.paginaIngresos = 0;
        state.paginaEgresos = 0;
        state.paginaIngresosEjecutados = 0;
        state.paginaEgresosEjecutados = 0;
    
        state.totalPresupuestos = 0;
        state.totalIngresos = 0;
        state.totalEgresos = 0;
        state.totalIngresosEjecutados = 0;
        state.totalEgresosEjecutados = 0;
    },
    'SET_PRESUPUESTOS'(state, presupuestos) {
        state.presupuestos = presupuestos.list.map(presupuesto => ({
            ...presupuesto,
            _rowVariant: (/true/i).test(presupuesto.cerrado) ? 'cerrado' : ''
        }));

        state.paginaPresupuestos = state.presupuestos.length;
        state.paginaIngresos = state.presupuestos.reduce((total, presupuesto) => total + parseFloat(presupuesto.totalIngresos), 0);
        state.paginaEgresos = state.presupuestos.reduce((total, presupuesto) => total + parseFloat(presupuesto.totalEgresos), 0);
        state.paginaIngresosEjecutados = state.presupuestos.reduce((total, presupuesto) => total + parseFloat(presupuesto.totalIngresosEjecutados), 0);
        state.paginaEgresosEjecutados = state.presupuestos.reduce((total, presupuesto) => total + parseFloat(presupuesto.totalEgresosEjecutados), 0);

        state.totalPresupuestos = presupuestos.totales.presupuestos;
        state.totalIngresos = presupuestos.totales.totalIngresos;
        state.totalEgresos = presupuestos.totales.totalEgresos;
        state.totalIngresosEjecutados = presupuestos.totales.totalIngresosEjecutados;
        state.totalEgresosEjecutados = presupuestos.totales.totalEgresosEjecutados;
    },
    'SET_PAGINATION'(state, params) {
        state.paginaActual = params.paginaActual;
        state.registrosPorPagina = params.registrosPorPagina;
        state.totalRegistros = params.totalRegistros;
        state.totalPaginas = params.totalPaginas;
    },

    'INICIALIZAR_RPT'(state) {
        state.rptPresupuestos = [];
    },
    'SET_RPT'(state, rpt) {
        state.rptPresupuestos = rpt;
    },

    'INICIALIZAR_LIST'(state) {
        state.list = [];
        state.selectList = [];
    },
    'SET_LIST_CARRERA_PERIODO'(state, presupuestos) {
        state.list = presupuestos.map(presupuesto => ({
            id: presupuesto.id,
            nombre: `${ moment(presupuesto.fechaDesde).format('DD/MM/YYYY')} - ${ moment(presupuesto.fechaHasta).format('DD/MM/YYYY')}`
        }));
        state.selectList = presupuestos.map(presupuesto =>  `${ moment(presupuesto.fechaDesde).format('DD/MM/YYYY')} - ${ moment(presupuesto.fechaHasta).format('DD/MM/YYYY')}`);
    },
};

const actions = {
    getPresupuestos: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            let queryString = '?pageSize=' + state.registrosPorPagina
                + '&pageNumber=' + state.paginaActual;

            if (state.facultad) {
                if (state.facultad.id != 0) {
                    queryString += '&facultadId=' + state.facultad.id;
                }
            }
            if (state.carrera) {
                if (state.carrera.id != 0) {
                    queryString += '&carreraId=' + state.carrera.id;
                }
            }
            if (state.periodoDesde) {
                if (state.periodoDesde != 0) {
                    queryString += '&periodoDesde=' + state.periodoDesde;
                }
            }
            if (state.periodoHasta) {
                if (state.periodoHasta != 0) {
                    queryString += '&periodoHasta=' + state.periodoHasta;
                }
            }
            if ((/true/i).test(state.incluirBorrados)) {
                queryString += '&incluirBorrados=true';
            }
            // if ((/true/i).test(state.incluirRevisiones)) {
            //     queryString += '&incluirRevisiones=true';
            // }

            apiAxios.get('presupuestos' + queryString)
                .then(res => {
                    if (res.headers.pagination){
                        const pagination= JSON.parse(res.headers.pagination)
                        var params = {
                            paginaActual: pagination.currentPage,
                            registrosPorPagina: pagination.itemsPerPage,
                            totalRegistros: pagination.totalItems,
                            totalPaginas: pagination.totalPages,
                        }    
                        commit('SET_PAGINATION', params);
                    }
                    commit('SET_PRESUPUESTOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
    deletePresupuesto ( {}, presupuestoId ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('presupuestos/' + presupuestoId + '/delete')
                .then(() => resolve())
				.catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    duplicarPresupuesto ( {}, params ) {
        return new Promise((resolve, reject) => {
            let presupuestoDuplicar = {
                carreraId: params.carreraId,
                periodo: params.periodo,
                fechaDesde: params.fechaDesde,
                fechaHasta: params.fechaHasta
            }
            apiAxios.post('presupuestos/' + params.presupuestoId + '/duplicar', presupuestoDuplicar)
                .then(() => resolve())
				.catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    abrirPresupuesto ( {}, presupuestoId ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('presupuestos/' + presupuestoId + '/abrir')
                .then(() => resolve())
				.catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    cerrarPresupuesto ( {}, presupuestoId ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('presupuestos/' + presupuestoId + '/cerrar')
                .then(() => resolve())
				.catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },

    getRptPresupuestos: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_RPT');

            let queryString = '?facultadId=' + params.facultad.id
                + '&periodo=' + params.periodo;

            apiAxios.get('presupuestos/rpt' + queryString)
                .then(res => {
                    commit('SET_RPT', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
    getListByCarreraPeriodo: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_LIST');

            let queryString = '?carreraId=' + params.carreraId
                + '&periodoDesde=' + params.periodo
                + '&periodoHasta=' + params.periodo;

            apiAxios.get('presupuestos/list' + queryString)
                .then(res => {
                    commit('SET_LIST_CARRERA_PERIODO', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },

};

const getters = {
    getField,

    rptPresupuestos(state) {
        return state.rptPresupuestos;
    },

    list (state) {
        return state.list;
    },
    selectList (state) {
        return state.selectList;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}