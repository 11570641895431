import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    carreraDatos: [],
};

const mutations = {
    updateField,

    'INICIALIZAR_DATOS'(state) {
        state.carreraDatos = [];
    },
    'SET_DATOS'(state, carreraDatos) {
        state.carreraDatos = carreraDatos;
    },
};

const actions = {
    getDatos ( { commit }, carreraId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DATOS');

            if (carreraId) {
                apiAxios.get(`carreras/${carreraId}`) 
                    .then(res => {
                        let carreraDatos = [
                            { 'nombre': 'facultad', 'campo': 'Facultad', 'valor': res.data.facultad, 'formato': '' },
                            { 'nombre': 'nombre', 'campo': 'Nombre', 'valor': res.data.nombre, 'formato': '' },
                            { 'nombre': 'tipoCarrera', 'campo': 'Tipo', 'valor': res.data.tipoCarrera, 'formato': '' },
                            { 'nombre': 'centroCostos', 'campo': 'Centro de Costos', 'valor': res.data.centroCostos, 'formato': '' },
                            { 'nombre': 'subcentroCostos', 'campo': 'Subcentro de Costos', 'valor': res.data.subcentroCostos, 'formato': '' },
                            { 'nombre': 'inactivo', 'campo': 'Inactivo', 'valor': res.data.inactivo? 'Si' : 'No', 'formato': '' },
                        ];

                        commit('SET_DATOS', carreraDatos);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })            
    },
};

const getters = {
    getField
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}