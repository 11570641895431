import Vue from 'vue';
import Vuex from 'vuex';

import cargo from './modules/cargo';
import cargoDatos from './modules/cargoDatos';
import cargos from './modules/cargos';
import carrera from './modules/carrera';
import carreraDatos from './modules/carreraDatos';
import carreras from './modules/carreras';
import centrosCostos from './modules/centrosCostos';
import concepto from './modules/concepto';
import conceptos from './modules/conceptos';
import cuentas from './modules/cuentas';
import documentos from './modules/documentos';
import facultad from './modules/facultad';
import facultadDatos from './modules/facultadDatos';
import facultades from './modules/facultades';
import indicadores from './modules/indicadores';
import personal from './modules/personal';
import personalDatos from './modules/personalDatos';
import personel from './modules/personel';
import personalContratacion from './modules/personalContratacion';
import personalContratacionDatos from './modules/personalContratacionDatos';
import personalContrataciones from './modules/personalContrataciones';
import personalDocumento from './modules/personalDocumento';
import personalDocumentos from './modules/personalDocumentos';
import personalDocumentosEstados from './modules/personalDocumentosEstados';
import presupuesto from './modules/presupuesto';
import presupuestoDatos from './modules/presupuestoDatos';
import presupuestos from './modules/presupuestos';
import presupuestoRenglon from './modules/presupuestoRenglon';
import presupuestoRenglonPersonal from './modules/presupuestoRenglonPersonal';
import rubro from './modules/rubro';
import rubroDatos from './modules/rubroDatos';
import rubros from './modules/rubros';
import rubrosConceptos from './modules/rubrosConceptos';
import subcentrosCostos from './modules/subcentrosCostos';
import tiposCarreras from './modules/tiposCarreras';

import empresa from './modules/empresa';
import spinnerProcesando from './modules/spinner-procesando';
import user from './modules/user';
import usuario from './modules/usuario';
import usuarios from './modules/usuarios';
import usuariosRoles from './modules/usuariosRoles';
import usuarioPerfil from './modules/usuarioPerfil';
import usuarioFacultades from './modules/usuarioFacultades';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        cargo,
        cargoDatos,
        cargos,
        carrera,
        carreraDatos,
        carreras,
        centrosCostos,
        concepto,
        conceptos,
        cuentas,
        documentos,
        facultad,
        facultadDatos,
        facultades,
        indicadores,
        personal,
        personalDatos,
        personel,
        personalContratacion,
        personalContratacionDatos,
        personalContrataciones,
        personalDocumento,
        personalDocumentos,
        personalDocumentosEstados,
        presupuesto,
        presupuestoDatos,
        presupuestos,
        presupuestoRenglon,
        presupuestoRenglonPersonal,
        rubro,
        rubroDatos,
        rubros,
        rubrosConceptos,
        subcentrosCostos,
        tiposCarreras,
        
        empresa,
        spinnerProcesando,
        user,
        usuario,
        usuarios,
        usuariosRoles,
        usuarioPerfil,
        usuarioFacultades
    }
});