import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import moment from 'moment';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    nombre: '',
    inactivo: false,
    rowVersion: null,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, cargoId) {
        state.id = cargoId;
        state.nombre = '';
        state.inactivo = false;
        state.rowVersion = null;
    },
    'SET_CARGO' (state, cargo) {
        state.nombre = cargo.nombre.trim();
        state.inactivo = (/true/i).test(cargo.inactivo);
        state.rowVersion = cargo.rowVersion;
    },
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, cargoId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', cargoId);

            if (cargoId != 0) {
                apiAxios.get('cargos/' + cargoId + '/abm' ) 
                    .then(res => {
                        commit('SET_CARGO', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
            
        })            
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            if (save) {
                let cargo= {
                    nombre: state.nombre.toUpperCase().trim(),
                    inactivo: (/true/i).test(state.inactivo),
                };

                let url = 'cargos';
                if (state.id != 0) {
                    url += '/' + state.id;
                    cargo.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, cargo)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}