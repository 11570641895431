import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import moment from 'moment';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    facultadId: 0,
    facultad: null,
    facultadNombre: null,
    carreraId: 0,
    carrera: null,
    carreraNombre: null,
    tipoCarrera: '',
    periodo: 0,
    periodoNombre: null,
    fechaDesde: moment(new Date()).format('YYYY-MM-DD'),
    fechaHasta: moment(new Date()).format('YYYY-MM-DD'),
    // numeroRevision: 0,
    pjeInflacionEstimada: 0,
    observaciones: '',
    rowVersion: null,

    renglones: [],
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, presupuestoId) {
        state.id = presupuestoId;
        state.facultadId = 0;
        state.facultad = null;
        state.facultadNombre = null;
        state.carreraId = 0;
        state.carrera = null;
        state.carreraNombre = null;
        state.tipoCarrera = '';
        state.periodo = 0;
        state.periodoNombre = null;
        state.fechaDesde = moment(new Date()).format('YYYY-MM-DD');
        state.fechaHasta = moment(new Date()).format('YYYY-MM-DD');
        // state.numeroRevision = 0;
        state.pjeInflacionEstimada = 0;
        state.observaciones = '';
        state.rowVersion = null;
        state.renglones = [];  
    },
    'SET_PRESUPUESTO' (state, params) {
        state.facultadId = params.presupuesto.facultad? params.presupuesto.facultad.id : 0;
        state.facultad = params.presupuesto.facultad;
        state.facultadNombre = params.presupuesto.facultad? params.presupuesto.facultad.nombre.trim() : null;
        state.carreraId = params.presupuesto.carrera? params.presupuesto.carrera.id : 0;
        state.carrera = params.presupuesto.carrera;
        state.carreraNombre = params.presupuesto.carrera? params.presupuesto.carrera.nombre.trim() : null;
        state.tipoCarrera = params.presupuesto.tipoCarrera;
        state.periodo = params.presupuesto.periodo;
        state.periodoNombre = params.presupuesto.periodo.toString().trim();
        state.fechaDesde = moment(params.presupuesto.fechaDesde).format('YYYY-MM-DD');
        state.fechaHasta = moment(params.presupuesto.fechaHasta).format('YYYY-MM-DD');
        // state.numeroRevision = params.presupuesto.numeroRevision;
        state.pjeInflacionEstimada = params.presupuesto.pjeInflacionEstimada;
        state.observaciones = params.presupuesto.observaciones;
        if (!params.duplicar) {
            state.rowVersion = params.presupuesto.rowVersion;
        }
        
        state.renglones = getRenglones(params.presupuesto.rubros);

        // state.renglones = getRenglones(params.presupuesto.renglones);

        if (params.duplicar) {
            /* Actualizamos la información de los renglones */
            state.renglones.forEach(rubro => {
                rubro.conceptos.forEach(concepto => {
                    concepto.items.forEach(item => {
                        item.id = getUUID();
                        item.itemId = 0;
                    })
                });
            });
        }

        calcularTotales(state);
    },
    'SET_FACULTAD'(state, facultad) {
        state.facultad = facultad;
        if (facultad) {
            state.facultadId = facultad.id? facultad.id : 0;
            state.facultadNombre = facultad.nombre? facultad.nombre.trim() : null;
        } else {
            state.facultadId = 0;
            state.facultadNombre = null;
        }
    },
    'SET_CARRERA'(state, carrera) {
        state.carrera = carrera;
        if (carrera) {
            state.carreraId = carrera.id? carrera.id : 0;
            state.carreraNombre = carrera.nombre? carrera.nombre.trim() : null;
            state.tipoCarrera = carrera.tipoCarrera? carrera.tipoCarrera.trim() : '';
        } else {
            state.carreraId = 0;
            state.carreraNombre = null;
            state.tipoCarrera = '';
        }
    },
    'ADD_RUBRO'(state, rubro) {
        let exists = getRubro(state, rubro.id);
        if (!exists) {
            state.renglones.push({
                id: rubro.id,
                nombre: `${rubro.codigo.toString().padStart(2, '0')} ${rubro.nombre.trim()}`,
                ingresos: rubro.ingresos,
                total: 0,
                conceptos: [],
                showConceptos: true
            })

            /* Ordenamos la lista de rubros */
            state.renglones = state.renglones.sort(function(a, b){
                if (a.nombre < b.nombre) { 
                    return -1; 
                }
                if (a.nombre > b.nombre) {
                    return 1;
                }
                return 0;
            })
        }
    },
    'DELETE_RUBRO'(state, rubroId) {
        let itemKey = state.renglones
            .findIndex(rubro => rubro.id == rubroId);

        if (itemKey != -1) {
            state.renglones.splice(itemKey, 1);
        }

        calcularTotales(state);
    },
    'ADD_CONCEPTO'(state, params) {
        let rubro = getRubro(state, params.rubroId);
        if (rubro) {
            let exists = rubro.conceptos.find(t => t.id == params.concepto.id);
            if (!exists) {
                rubro.conceptos.push({
                    id: params.concepto.id,
                    rubroId: params.rubroId,
                    nombre: `${params.concepto.codigo.toString().padStart(2, '0')} ${params.concepto.nombre.trim()}`,
                    honorarios: params.concepto.honorarios,
                    honorariosHoras: params.concepto.honorariosHoras,
                    ingresoCuotas: params.concepto.ingresoCuotas,
                    total: 0,
                    items: [],
                    showItems: false
                })

                /* Ordenamos la lista de conceptos */
                rubro.conceptos = rubro.conceptos.sort(function(a, b){
                    if (a.nombre < b.nombre) { 
                        return -1; 
                    }
                    if (a.nombre > b.nombre) {
                        return 1;
                    }
                    return 0;
                })
            }
        }
    },
    'DELETE_CONCEPTO'(state, params) {
        let rubro = getRubro(state, params.rubroId);
        if (rubro) {
            let itemKey = rubro.conceptos
                .findIndex(concepto => concepto.id == params.conceptoId);
    
            if (itemKey != -1) {
                rubro.conceptos.splice(itemKey, 1);
            }
        }

        calcularTotales(state);
    },
    'SET_ITEM'(state, params) {
        if (params.itemId == 0) {
            let concepto = getConcepto(state, params);
            if (concepto) {
                let item = {
                    id: getUUID(),
                    rubroId: params.rubroId,
                    conceptoId: params.conceptoId,
                    itemId: 0,
                    personalId: params.item.personalId? params.item.personalId : 0,
                    personal: params.item.personal? params.item.personal.trim() : '',
                    titulo: params.item.titulo? params.item.titulo.trim() : '',
                    subcantidad: params.item.subcantidad,
                    cantidad: params.item.cantidad,
                    precioUnitario: params.item.precioUnitario,
                    subtotal: parseFloat(params.item.cantidad) * parseFloat(params.item.precioUnitario),
                    observaciones: params.item.observaciones,
                    _showDetails: true
                }

                if ((/true/i).test(concepto.ingresoCuotas) || (/true/i).test(concepto.honorariosHoras)) {
                    item.subtotal = parseFloat(params.item.subcantidad)
                        * parseFloat(params.item.cantidad)
                        * parseFloat(params.item.precioUnitario);
                }

                concepto.items.push(item);
            }
        } else {
            let concepto = getConcepto(state, params);
            let item = getItem(state, params);
            if (item) {
                item.personalId = params.item.personalId? params.item.personalId : 0,
                item.personal = params.item.personal? params.item.personal.trim() : '',
                item.titulo = params.item.titulo? params.item.titulo.trim() : '',
                item.subcantidad = params.item.subcantidad,
                item.cantidad = params.item.cantidad;
                item.precioUnitario = params.item.precioUnitario;
                item.observaciones = params.item.observaciones.trim();

                item.subtotal = parseFloat(item.cantidad) * parseFloat(item.precioUnitario);

                if ((/true/i).test(concepto.ingresoCuotas) || (/true/i).test(concepto.honorariosHoras)) {
                    item.subtotal = parseFloat(item.subcantidad)
                        * parseFloat(item.cantidad)
                        * parseFloat(item.precioUnitario);
                }
            }
        }

        calcularTotales(state);
    },
    'UPDATE_ITEM_SUBCANTIDAD'(state, params) {
        let item = getItem(state, params);
        if (item) {
            item.subcantidad = params.subcantidad;

            item.subtotal = parseFloat(item.subcantidad) 
                * parseFloat(item.cantidad) 
                * parseFloat(item.precioUnitario);
                
            calcularTotales(state);
        }
    },
    'UPDATE_ITEM_CANTIDAD'(state, params) {
        let concepto = getConcepto(state, params);

        let item = getItem(state, params);
        if (item) {
            item.cantidad = params.cantidad;

            item.subtotal = parseFloat(item.cantidad) * parseFloat(item.precioUnitario);

            if ((/true/i).test(concepto.ingresoCuotas) || (/true/i).test(concepto.honorariosHoras)) {
                item.subtotal = parseFloat(item.subcantidad)
                    * parseFloat(item.cantidad)
                    * parseFloat(item.precioUnitario);
            }

            calcularTotales(state);
        }
    },
    'UPDATE_ITEM_PRECIO_UNITARIO'(state, params) {
        let concepto = getConcepto(state, params);

        let item = getItem(state, params);
        if (item) {
            item.precioUnitario = params.precioUnitario;

            item.subtotal = parseFloat(item.cantidad) * parseFloat(item.precioUnitario);

            if ((/true/i).test(concepto.ingresoCuotas) || (/true/i).test(concepto.honorariosHoras)) {
                item.subtotal = parseFloat(item.subcantidad)
                    * parseFloat(item.cantidad)
                    * parseFloat(item.precioUnitario);
            }
            calcularTotales(state);
        }
    },
    'DELETE_ITEM'(state, params) {
        let concepto = getConcepto(state, params);
        if (concepto) {
            let itemKey = concepto.items
                .findIndex(item => item.id == params.itemId);

            if (itemKey != -1) {
                concepto.items.splice(itemKey, 1);
                calcularTotales(state);
            }
        }
    },
};

function getRubro (state, rubroId) {
    return state.renglones.find(t => t.id == rubroId);
}
function getConcepto (state, params) {
    let rubro = getRubro(state, params.rubroId);
    if (rubro) {
        return rubro.conceptos.find(t => t.id == params.conceptoId);
    }
}
function getItem (state, params) {
    let rubro = getRubro(state, params.rubroId);
    if (rubro) {
        let concepto = rubro.conceptos.find(t => t.id == params.conceptoId);
        if (concepto) {
            return concepto.items.find(t => t.id == params.itemId);
        }
    }
    return null;
}
function getUUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
}
function getRenglones (renglones) {
    let renglonesPresupuesto = [];

    if (renglones) {
        renglones.forEach(rubroPresupuesto => {
            let rubro = {
                id: rubroPresupuesto.id,
                nombre: rubroPresupuesto.nombre.trim(),
                ingresos: rubroPresupuesto.ingresos,
                total: 0,
                conceptos: [],
                showConceptos: false
            }

            rubroPresupuesto.conceptos.forEach(conceptoPresupuesto => {
                let concepto = {
                    id: conceptoPresupuesto.id,
                    rubroId: conceptoPresupuesto.rubroId,
                    nombre: conceptoPresupuesto.nombre,
                    honorarios: conceptoPresupuesto.honorarios,
                    honorariosHoras: conceptoPresupuesto.honorariosHoras,
                    ingresoCuotas: conceptoPresupuesto.ingresoCuotas,
                    total: 0,
                    items: [],
                    showItems: false
                }
                
                conceptoPresupuesto.items.forEach(itemPresupuesto => {
                    let item = {
                        id: itemPresupuesto.id,
                        rubroId: itemPresupuesto.rubroId,
                        conceptoId: itemPresupuesto.conceptoId,
                        itemId: itemPresupuesto.id,
                        personalId: itemPresupuesto.personalId,
                        personal: itemPresupuesto.personal,
                        titulo: itemPresupuesto.titulo,
                        subcantidad: itemPresupuesto.subcantidad,
                        cantidad: itemPresupuesto.cantidad,
                        precioUnitario: itemPresupuesto.precioUnitario,
                        subtotal: parseFloat(itemPresupuesto.cantidad) * parseFloat(itemPresupuesto.precioUnitario),
                        observaciones: itemPresupuesto.observaciones,
                        _showDetails: true
                    }

                    if ((/true/i).test(conceptoPresupuesto.ingresoCuotas) || (/true/i).test(conceptoPresupuesto.honorariosHoras)) {
                        item.subtotal = parseFloat(item.subcantidad)
                            * parseFloat(item.cantidad)
                            * parseFloat(item.precioUnitario);
                    }

                    concepto.items.push(item);
                })

                rubro.conceptos.push(concepto);
            })

            renglonesPresupuesto.push(rubro);
        });
    }
    return renglonesPresupuesto;
}
// function getRenglones (renglones) {
//     let renglonesPresupuesto = [];

//     if (renglones) {
//         renglones.sort(function(a, b){
//             if (a.rubro < b.rubro) { 
//                 return -1; 
//             }
//             if (a.rubro > b.rubro) {
//                 return 1;
//             }
//             if (a.concepto < b.concepto) { 
//                 return -1; 
//             }
//             if (a.concepto > b.concepto) {
//                 return 1;
//             }
//             return 0;
//         }).forEach(renglon => {
//             let rubroPresupuesto = renglonesPresupuesto.find(t => t.id == renglon.rubroId);
//             if (rubroPresupuesto) {
//                 let conceptoPresupuesto = rubroPresupuesto.conceptos.find(t => t.id == renglon.conceptoId);
//                 if (conceptoPresupuesto) {
//                     /* Agregamos el Item */
//                     let item = {
//                         id: renglon.id,
//                         rubroId: renglon.rubroId,
//                         conceptoId: renglon.conceptoId,
//                         itemId: renglon.id,
//                         personalId: renglon.personalId,
//                         personal: renglon.personal,
//                         titulo: renglon.titulo,
//                         subcantidad: renglon.subcantidad,
//                         cantidad: renglon.cantidad,
//                         precioUnitario: renglon.precioUnitario,
//                         subtotal: parseFloat(renglon.cantidad) * parseFloat(renglon.precioUnitario),
//                         observaciones: renglon.observaciones,
//                         _showDetails: true
//                     }

//                     if ((/true/i).test(conceptoPresupuesto.ingresoCuotas) || (/true/i).test(conceptoPresupuesto.honorariosHoras)) {
//                         item.subtotal = parseFloat(renglon.subcantidad)
//                             * parseFloat(renglon.cantidad)
//                             * parseFloat(renglon.precioUnitario);
//                     }
        
//                     conceptoPresupuesto.items.push(item);
//                 } else {
//                     /* Agregamos el Concepto + Item */
//                     let concepto = {
//                         id: renglon.conceptoId,
//                         rubroId: renglon.rubroId,
//                         nombre: renglon.concepto,
//                         honorarios: renglon.honorarios,
//                         honorariosHoras: renglon.honorariosHoras,
//                         ingresoCuotas: renglon.ingresoCuotas,
//                         total: 0,
//                         items: [],
//                         showItems: false
//                     }
//                     let item = {
//                         id: renglon.id,
//                         rubroId: renglon.rubroId,
//                         conceptoId: renglon.conceptoId,
//                         itemId: renglon.id,
//                         personalId: renglon.personalId,
//                         personal: renglon.personal,
//                         titulo: renglon.titulo,
//                         subcantidad: renglon.subcantidad,
//                         cantidad: renglon.cantidad,
//                         precioUnitario: renglon.precioUnitario,
//                         subtotal: parseFloat(renglon.cantidad) * parseFloat(renglon.precioUnitario),
//                         observaciones: renglon.observaciones,
//                         _showDetails: true
//                     }

//                     if ((/true/i).test(concepto.ingresoCuotas) || (/true/i).test(concepto.honorariosHoras)) {
//                         item.subtotal = parseFloat(renglon.subcantidad)
//                             * parseFloat(renglon.cantidad)
//                             * parseFloat(renglon.precioUnitario);
//                     }

//                     concepto.items.push(item);
//                     rubroPresupuesto.conceptos.push(concepto);
//                 }
//             } else {
//                 /* Agregamos el Rubro + Concepto + Item */
//                 let rubro = {
//                     id: renglon.rubroId,
//                     nombre: renglon.rubro.trim(),
//                     ingresos: renglon.rubroIngresos,
//                     total: 0,
//                     conceptos: [],
//                     showConceptos: false
//                 }
//                 let concepto = {
//                     id: renglon.conceptoId,
//                     rubroId: renglon.rubroId,
//                     nombre: renglon.concepto,
//                     honorarios: renglon.honorarios,
//                     honorariosHoras: renglon.honorariosHoras,
//                     ingresoCuotas: renglon.ingresoCuotas,
//                     total: 0,
//                     items: [],
//                     showItems: false
//                 }
//                 let item = {
//                     id: renglon.id,
//                     rubroId: renglon.rubroId,
//                     conceptoId: renglon.conceptoId,
//                     itemId: renglon.id,
//                     personalId: renglon.personalId,
//                     personal: renglon.personal,
//                     titulo: renglon.titulo,
//                     subcantidad: renglon.subcantidad,
//                     cantidad: renglon.cantidad,
//                     precioUnitario: renglon.precioUnitario,
//                     subtotal: parseFloat(renglon.cantidad) * parseFloat(renglon.precioUnitario),
//                     observaciones: renglon.observaciones,
//                     _showDetails: true
//                 }

//                 if ((/true/i).test(concepto.ingresoCuotas) || (/true/i).test(concepto.honorariosHoras)) {
//                     item.subtotal = parseFloat(renglon.subcantidad)
//                         * parseFloat(renglon.cantidad)
//                         * parseFloat(renglon.precioUnitario);
//                 }

//                 concepto.items.push(item);
//                 rubro.conceptos.push(concepto);
//                 renglonesPresupuesto.push(rubro);
//             }
//         });
//     }
//     return renglonesPresupuesto;
// }

function calcularTotales(state) {
    state.renglones.forEach(rubro => {
        rubro.conceptos.forEach(concepto => {
            concepto.total = 0;
            if (concepto.items) {
                concepto.total = concepto.items.reduce((total, item) => total + parseFloat(item.subtotal), 0);
            }
        });

        rubro.total = rubro.conceptos.reduce((total, concepto) => total + parseFloat(concepto.total), 0);
    });
}
function getRenglonesPresupuesto (state) {
    let renglones = [];
    
    state.renglones.forEach(rubro => {
        rubro.conceptos.forEach(concepto => {
            concepto.items.forEach(item => {
                renglones.push({
                    id: item.itemId,
                    personalId: item.personalId,
                    conceptoId: item.conceptoId,
                    titulo: item.titulo.toUpperCase().trim(),
                    subcantidad: parseInt(item.subcantidad),
                    cantidad: parseInt(item.cantidad),
                    precioUnitario: parseFloat(item.precioUnitario),
                    observaciones: item.observaciones.trim(),
                    cargos: []
                });
            });
        });
    });
    
    return renglones;
}

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, params ) {
        return new Promise((resolve, reject) => {
            let presupuestoId = params.presupuestoId;
            if (params.duplicar) {
                presupuestoId = 0;
            }
            
            /* Si estoy duplicando el presupuesto, no paso la referencia */
            commit('INICIALIZAR', presupuestoId);

            let url = 'presupuestos/abm';
            if (params.presupuestoId != 0) {
                url = `presupuestos/${params.presupuestoId}/abm`;
            }

            apiAxios.get(url)
                .then(res => {
                    params.presupuesto = res.data;
                    commit('SET_PRESUPUESTO', params);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })            
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            let renglones = getRenglonesPresupuesto(state);

            if (save) {
                let presupuesto= {
                    carreraId: state.carreraId,
                    periodo: parseInt(state.periodo),
                    fechaDesde: moment(state.fechaDesde).format('YYYY-MM-DD'),
                    fechaHasta: moment(state.fechaHasta).format('YYYY-MM-DD'),
                    // numeroRevision: state.numeroRevision,
                    pjeInflacionEstimada: parseFloat(state.pjeInflacionEstimada),
                    observaciones: state.observaciones.trim(),
                    renglones
                };

                let url = 'presupuestos';
                if (state.id != 0) {
                    url += '/' + state.id;
                    presupuesto.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, presupuesto)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
    setFacultad: ({commit}, facultad) => {
        commit('SET_FACULTAD', facultad);
    },
    setCarrera: ({commit}, carrera) => {
        commit('SET_CARRERA', carrera);
    },
    addRubro: ({ commit }, rubro) => {
        commit('ADD_RUBRO', rubro);
    },
    deleteRubro: ({ commit }, rubroId) => {
        commit('DELETE_RUBRO', rubroId);
    },
    addConcepto: ({ commit }, params) => {
        commit('ADD_CONCEPTO', params);
    },
    deleteConcepto: ({ commit }, params) => {
        commit('DELETE_CONCEPTO', params);
    },
    setItem: ({ commit }, params) => {
        commit('SET_ITEM', params);
    },
    updateItemSubcantidad: ({ commit }, params) => {
        commit('UPDATE_ITEM_SUBCANTIDAD', params);
    },
    updateItemCantidad: ({ commit }, params) => {
        commit('UPDATE_ITEM_CANTIDAD', params);
    },
    updateItemPrecioUnitario: ({ commit }, params) => {
        commit('UPDATE_ITEM_PRECIO_UNITARIO', params);
    },
    deleteItem: ({ commit }, params) => {
        commit('DELETE_ITEM', params);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}