import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    usuarioId: 0,
    username: '',
    facultades: [],
    /* Paginacion */
    paginaActual: 1,
    registrosPorPagina: 10,
    totalRegistros: 0,
    totalPaginas: 0,
};

const mutations = {
    updateField,

    'INICIALIZAR_USUARIO'(state, usuarioId) {
        state.usuarioId = usuarioId;
        state.username = '';
        state.facultades = [];
        state.paginaActual = 1;
        state.totalRegistros = 0;
    },
    'SET_USUARIO'(state, usuario) {
        state.username = usuario.username;
        state.facultades = usuario.facultades;
        state.totalRegistros = state.facultades.length;
    },
    'ADD_FACULTAD'(state, facultad) {
        let itemKey = state.facultades
            .findIndex(t => t.id == facultad.id);

        if (itemKey == -1) {
            state.facultades.push({
                id: facultad.id,
                nombre: facultad.nombre
            })
    
            state.totalRegistros = state.facultades.length;
        }
    },
    'DELETE_FACULTAD'(state, facultadId) {
        let itemKey = state.facultades
            .findIndex(facultad => facultad.id == facultadId);

        if (itemKey != -1) {
            state.facultades.splice(itemKey, 1);
        }

        state.totalRegistros = state.facultades.length;
    },
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, usuarioId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_USUARIO', usuarioId);

            if (usuarioId != 0) {
                apiAxios.get('usuarios/' + usuarioId + '/facultades' ) 
                    .then(res => {
                        commit('SET_USUARIO', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })
    },
    addFacultad: ({ state, commit }, facultad) => {
        return new Promise((resolve, reject) => {
            apiAxios.post('usuarios/' + state.usuarioId + '/facultades/' + facultad.id ) 
                .then(() => {
                    commit('ADD_FACULTAD', facultad);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })        
    },
    deleteFacultad: ({ state, commit }, facultadId) => {
        return new Promise((resolve, reject) => {
            apiAxios.post('usuarios/' + state.usuarioId + '/facultades/' + facultadId + '/delete' ) 
                .then(() => {
                    commit('DELETE_FACULTAD', facultadId);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })        
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}