import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import moment from 'moment';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    nombre: '',
    centroCostosId: 0,
    centroCostos: null,
    centroCostosNombre: null,
    inactivo: false,
    rowVersion: null,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, facultadId) {
        state.id = facultadId;
        state.nombre = '';
        state.centroCostosId = 0;
        state.centroCostos = null;
        state.centroCostosNombre = null;
        state.inactivo = false;
        state.rowVersion = null;
    },
    'SET_FACULTAD' (state, facultad) {
        state.nombre = facultad.nombre.trim();
        state.centroCostosId = facultad.centroCostosId;
        state.centroCostos = facultad.centroCostos;
        state.centroCostosNombre = facultad.centroCostos? facultad.centroCostos.nombre.trim() : null;
        state.inactivo = (/true/i).test(facultad.inactivo);
        state.rowVersion = facultad.rowVersion;
    },
    'SET_CENTRO_COSTOS'(state, centroCostos) {
        state.centroCostos = centroCostos;
        if (centroCostos) {
            state.centroCostosId = centroCostos.id? centroCostos.id : 0;
            state.centroCostosNombre = centroCostos.nombre? centroCostos.nombre.trim() : null;
        } else {
            state.centroCostosId = 0;
            state.centroCostosNombre = null;
        }
    },
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, facultadId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', facultadId);

            if (facultadId != 0) {
                apiAxios.get(`facultades/${facultadId}/abm`) 
                    .then(res => {
                        commit('SET_FACULTAD', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
            
        })            
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            if (save) {
                let facultad= {
                    nombre: state.nombre.toUpperCase().trim(),
                    centroCostosId: state.centroCostosId,
                    inactivo: (/true/i).test(state.inactivo),
                };

                let url = 'facultades';
                if (state.id != 0) {
                    url += '/' + state.id;
                    facultad.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, facultad)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
    setCentroCostos: ( { commit }, centroCostos) => {
        commit('SET_CENTRO_COSTOS', centroCostos);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}