import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    facultades: [],
    /* Paginacion */
    paginaActual: 1,
    registrosPorPagina: 10,
    totalRegistros: 0,
    totalPaginas: 1,
    nombre: '',
    incluirInactivos: false,

    list: [],
    selectList: [],
};

const mutations = {
    updateField,

    'INICIALIZAR'(state) {
        state.facultades = [];
        state.totalRegistros = 0;
    },
    'SET_FACULTADES'(state, facultades) {
        state.facultades = facultades;
        state.totalRegistros = facultades.length;
        if (state.registrosPorPagina != 0) {
            state.totalPaginas = Math.ceil(state.totalRegistros / state.registrosPorPagina);
        } else {
            state.totalPaginas = 1;
        }
    },

    'INICIALIZAR_LIST'(state) {
        state.list = [];
        state.selectList = [];
    },
    'SET_LIST'(state, facultades) {
        state.list = facultades;
        state.selectList = facultades.map(facultad => facultad.nombre.trim());
    },
};

const actions = {
    getFacultades: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            let queryString = '';

            if (state.nombre.trim() != '') {
                queryString += (queryString == ''? '?' : '&') + 'nombre=' + state.nombre.trim();
            }

            if ((/true/i).test(state.incluirInactivos)) {
                queryString += (queryString == ''? '?' : '&') + 'incluirInactivos=true';
            }

            apiAxios.get('facultades' + queryString)
                .then(res => {
                    commit('SET_FACULTADES', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
    getList: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_LIST');

            apiAxios.get('facultades/list')
                .then(res => {
                    commit('SET_LIST', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
};

const getters = {
    getField,

    list (state) {
        return state.list;
    },
    selectList (state) {
        return state.selectList;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}