import axios from 'axios';
import store from '../store/store';

const api = axios.create ({
    baseURL: '/presupuestos/api/',
    // baseURL: 'http://localhost:5000/api/',
});

api.interceptors.request.use(function(config) {
    const token = store.getters['user/tokenString'];

    if(token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function(err) {
    return Promise.reject(err);
});

export default api;