import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import moment from 'moment';
import helpers from '../../mixins/helpers';

const state = {
};

const mutations = {
    updateField,
};

const getters = {
    getField,
};

const actions = {
    uploadDocumento ( {}, params ) {
        return new Promise((resolve, reject) => {
            let error;
            let fileName = params.file.name;
            let fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
            let fileSize = parseFloat(params.file.size / 1048576);

            if (fileExtension != 'pdf') {
                    error = 'El tipo de archivo seleccionado no es válido. Sólo se permiten documentos PDF.';
            }

            if (fileSize >= 3 ) {
                error = 'El archivo seleccionado tiene un peso mayor al permitido. Sólo se permiten imágenes de hasta 3MB.';
            }

            if (error) {
                reject(error);
            } else {
				let formData = new FormData();
                formData.append('file', params.file);

                let options = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }				

				apiAxios.post('documentos', formData, options)
                    .then(() => resolve())
					.catch(error => reject(helpers.methods.getErrorMessage(error)));
			}
        })
    },	
    deleteDocumento ( {}, documentoId ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('documentos/' + documentoId + '/delete')
                .then(() => resolve())
				.catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    descargarDocumento: ( {}, documentoId ) => {
        return new Promise((resolve, reject) => {
            apiAxios.get('documentos/' + documentoId + '/descargar', { responseType: 'blob' } ) 
                .then(res => {
                    saveAs(res.data, 'documento.pdf');
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },    
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}