import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';
import moment from 'moment';

const state = {
    /* Datos del presupuesto */
    presupuestoId: 0,
    carreraPresupuestoId: 0,
    carreraPresupuesto: null,
    periodoPresupuesto: null,
    fechaDesdePresupuesto: null,
    fechaHastaPresupuesto: null,

    personalDocumentos: [],
    /* Paginacion */
    paginaActual: 1,
    registrosPorPagina: 10,
    totalRegistros: 0,
    totalPaginas: 0,

    personal: null,
    personalDocumentoEstado: null,
    carrera: null,
    periodoDesdeMes: 0,
    periodoDesdeMesNombre: null,
    periodoDesdeAnio: 0,
    periodoHastaMes: 0,
    periodoHastaMesNombre: null,
    periodoHastaAnio: 0,
    incluirBorrados: false,

    documentosFaltantes: [],
    paginaActualFaltantes: 1,
    registrosPorPaginaFaltantes: 10,
    totalRegistrosFaltantes: 0,
    totalPaginasFaltantes: 0,
};

const mutations = {
    updateField,

    'INICIALIZAR'(state, presupuestoId) {
        /* Datos del presupuesto */
        state.presupuestoId = presupuestoId;
        state.carreraPresupuestoId = 0;
        state.carreraPresupuesto = null;
        state.periodoPresupuesto = null;
        state.fechaDesdePresupuesto = null;
        state.fechaHastaPresupuesto = null;
    

        let mes = new Date().getMonth() + 1;
        let anio = new Date().getFullYear();
        let periodoDesde = new Date(anio, mes, 1);
        periodoDesde.setMonth(periodoDesde.getMonth() - 1);

        state.periodoDesdeMes= new Date(periodoDesde).getMonth() + 1;
        state.periodoDesdeMesNombre= helpers.methods.getMesNombre(state.periodoDesdeMes);
        state.periodoDesdeAnio= anio;

        mes = new Date().getMonth() + 1;
        anio = new Date().getFullYear();
        let periodoHasta = new Date(anio, mes, 1);        
        periodoHasta.setMonth(periodoDesde.getMonth() + 1);

        state.periodoHastaMes= new Date(periodoHasta).getMonth() + 1;
        state.periodoHastaMesNombre= helpers.methods.getMesNombre(state.periodoHastaMes);
        state.periodoHastaAnio= new Date(periodoHasta).getFullYear();

        state.personalDocumentos = [];
    },
    'SET_PRESUPUESTO'(state, presupuesto) {
        state.carreraPresupuestoId = presupuesto.carreraId;
        state.carreraPresupuesto = presupuesto.carrera;
        state.periodoPresupuesto = presupuesto.periodo.toString();
        state.fechaDesdePresupuesto = moment(presupuesto.fechaDesde).format('YYYY-MM-DD');
        state.fechaHastaPresupuesto = moment(presupuesto.fechaHasta).format('YYYY-MM-DD');
    },
    'SET_PERSONAL_DOCUMENTOS'(state, personalDocumentos) {
        state.personalDocumentos = personalDocumentos;
    },
    'SET_PAGINATION'(state, params) {
        state.paginaActual = params.paginaActual;
        state.registrosPorPagina = params.registrosPorPagina;
        state.totalRegistros = params.totalRegistros;
        state.totalPaginas = params.totalPaginas;
    },

    'INICIALIZAR_DOCUMENTOS_FALTANTES'(state) {
        state.documentosFaltantes = [];
        state.totalRegistrosFaltantes = 0;
        state.totalPaginasFaltantes = 1;
    },
    'SET_DOCUMENTOS_FALTANTES'(state, documentosFaltantes) {
        state.documentosFaltantes = documentosFaltantes;
        state.totalRegistrosFaltantes = documentosFaltantes.length;
    },
};

const actions = {
    inicializar ( { commit }, presupuestoId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', presupuestoId);
            
            if (presupuestoId != 0) {
                apiAxios.get('presupuestos/' + presupuestoId) 
                    .then(res => {
                        commit('SET_PRESUPUESTO', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })
    },
    getPersonalDocumentos: ({ commit }) => {
        return new Promise((resolve, reject) => {
            /* Filtramos por carrera y período para incluir las revisiones */
            let queryString = '?pageSize=' + state.registrosPorPagina
                + '&pageNumber=' + state.paginaActual;
            
            if (state.presupuestoId != 0) {
                queryString += '&carreraId=' + state.carreraPresupuestoId
                    + '&periodo=' + state.periodoPresupuesto;
            }

            if (state.personal) {
                if (state.personal.id != 0) {
                    queryString += '&personalId=' + state.personal.id;
                }
            }
            if (state.personalDocumentoEstado) {
                if (state.personalDocumentoEstado.id != 0) {
                    queryString += '&personalDocumentoEstadoId=' + state.personalDocumentoEstado.id;
                }
            }

            if (state.presupuestoId == 0) {
                let periodoDesde = parseInt(state.periodoDesdeAnio).toString().trim()
                    + '-' + helpers.methods.getMesNumero(state.periodoDesdeMesNombre).toString().trim()
                    + '-01';
                let periodoHasta = parseInt(state.periodoHastaAnio).toString().trim()
                    + '-' + helpers.methods.getMesNumero(state.periodoHastaMesNombre).toString().trim()
                    + '-01';

                queryString += '&periodoDesde=' + moment(periodoDesde).format('YYYY-MM-DD')
                    +  '&periodoHasta=' + moment(periodoHasta).format('YYYY-MM-DD');
                
                if (state.carrera) {
                    if (state.carrera.id) {
                        if (state.carrera.id != 0) {
                            queryString += '&carreraId=' + state.carrera.id;
                        }
                    }
                }
            }

            if ((/true/i).test(state.incluirBorrados)) {
                queryString += '&incluirBorrados=true';
            }

            apiAxios.get('personal-documentos' + queryString)
                .then(res => {
                    if (res.headers.pagination){
                        const pagination= JSON.parse(res.headers.pagination)
                        var params = {
                            paginaActual: pagination.currentPage,
                            registrosPorPagina: pagination.itemsPerPage,
                            totalRegistros: pagination.totalItems,
                            totalPaginas: pagination.totalPages,
                        }    
                        commit('SET_PAGINATION', params);
                    }
                    commit('SET_PERSONAL_DOCUMENTOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
    deleteDocumento ( {}, documentoId ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('personal-documentos/' + documentoId + '/delete')
                .then(() => resolve())
				.catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    descargarDocumento: ( {}, documentoId ) => {
        return new Promise((resolve, reject) => {
            let filename = 'documento.pdf';
            apiAxios.get('personal-documentos/' + documentoId) 
                .then(res => {
                    if (res.data.fileName) {
                        filename = res.data.fileName.trim();
                    }
                    return apiAxios.get('personal-documentos/' + documentoId + '/descargar', { responseType: 'blob' } ); 
                })
                .then(res => {
                    saveAs(res.data, filename);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    getDocumentosFaltantes: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DOCUMENTOS_FALTANTES');

            let queryString = '?carreraId=' + state.carreraPresupuestoId
                + '&periodo=' + state.periodoPresupuesto;

            apiAxios.get('personal-documentos/faltantes' + queryString)
                .then(res => {
                    commit('SET_DOCUMENTOS_FALTANTES', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
};

const getters = {
    getField,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}