import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import moment from 'moment';
import helpers from '../../mixins/helpers';

const state = {
    /* Datos del presupuesto */
    presupuestoId: 0,
    facultadId: 0,
    facultad: null,
    facultadNombre: null,
    carreraId: 0,
    carrera: null,
    carreraNombre: null,
    periodo: null,
    periodoNombre: null,
    fechaDesde: null,
    fechaHasta: null,

    /* Datos del personal */
    personalId: 0,
    personal: null,
    personalNombre: null,
    personalReadOnly: false,

    /* Datos del presupuesto del personal */
    horasPersonal: 0,
    mesesPersonal: 0,
    precioUnitarioPersonal: 0,
    honorariosHorasPersonal: true,

    /* Datos del documento */
    periodoDocumento: moment(new Date()).format('YYYY-MM-DD'),
    periodoDocumentoDia: 1,
    periodoDocumentoMes: 0,
    periodoDocumentoMesNombre: null,
    periodoDocumentoAnio: 0,
    file: null,
    observaciones: ''
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, params) {
        state.presupuestoId = params.presupuestoId;
        state.facultadId = 0;
        state.facultad = null;
        state.facultadNombre = null;
        state.carreraId = 0;
        state.carrera = null;
        state.carreraNombre = null;
        state.periodo = null;
        state.periodoNombre = null;
        state.fechaDesde = null;
        state.fechaHasta = null;

        state.personalId = params.personalId;
        state.personal = null;
        state.personalNombre = null;
        state.personalReadOnly = params.personalId != 0;

        state.horasPersonal = 0;
        state.mesesPersonal = 0;
        state.precioUnitarioPersonal = 0;
        state.honorariosHorasPersonal = true;

        let fecha = new Date();
        state.periodoDocumento = moment(fecha).format('YYYY-MM-DD');
        state.periodoDocumentoMes= new Date(fecha).getMonth() + 1;
        state.periodoDocumentoMesNombre= helpers.methods.getMesNombre(state.periodoDocumentoMes);
        state.periodoDocumentoAnio= new Date(fecha).getFullYear();
        state.file = null;
        state.observaciones = '';
    },
    'SET_PRESUPUESTO'(state, presupuesto) {
        state.carreraNombre = presupuesto.carrera;
        state.periodoNombre = presupuesto.periodo.toString();
        state.fechaDesde = moment(presupuesto.fechaDesde).format('YYYY-MM-DD');
        state.fechaHasta = moment(presupuesto.fechaHasta).format('YYYY-MM-DD');
    },
    'SET_PERSONAL'(state, personal) {
        state.personal = personal;
        if (personal) {
            state.personalId = personal.id? personal.id : 0;
            state.personalNombre = personal.nombreCompleto? personal.nombreCompleto.trim() : null;
        } else {
            state.personalId = 0;
            state.personalNombre = null;
        }

        state.horasPersonal = 0;
        state.mesesPersonal = 0;
        state.precioUnitarioPersonal = 0;
        state.honorariosHorasPersonal = true;
    },
    'INICIALIZAR_PRESUPUESTO_PERSONAL'(state) {
        state.horasPersonal = 0;
        state.mesesPersonal = 0;
        state.precioUnitarioPersonal = 0;
        state.honorariosHorasPersonal = true;
    },
    'SET_PRESUPUESTO_PERSONAL'(state, presupuestoPersonal) {
        if (presupuestoPersonal) {
            state.horasPersonal = parseInt(presupuestoPersonal.horas);
            state.mesesPersonal = parseInt(presupuestoPersonal.meses);
            state.precioUnitarioPersonal = parseFloat(presupuestoPersonal.precioUnitario);
            state.honorariosHorasPersonal = (/true/i).test(presupuestoPersonal.honorariosHoras);
        }
    },
};

const getters = {
    getField,
};

const actions = {
    inicializar ({ commit }, params) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', params);

            if (params.personalId != 0) {
                apiAxios.get(`presupuestos/${params.presupuestoId}`)
                    .then(res => {
                        commit('SET_PRESUPUESTO', res.data);
                        return apiAxios.get(`personal/${params.personalId}`)
                    })
                    .then(res => {
                        commit('SET_PERSONAL', res.data);
                        return apiAxios.get(`presupuestos/${params.presupuestoId}/personal/${params.personalId}`)
                    })
                    .then(res => {
                        commit('SET_PRESUPUESTO_PERSONAL', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                if (params.presupuestoId != 0) {
                    apiAxios.get(`presupuestos/${params.presupuestoId}`) 
                        .then(res => {
                            commit('SET_PRESUPUESTO', res.data);
                            resolve();
                        })
                        .catch(error => reject(helpers.methods.getErrorMessage(error)));
                } else {
                    resolve();
                }
            }
        })
    },
    setPersonal: ({commit}, personal) => {
        return new Promise((resolve, reject) => {
            commit('SET_PERSONAL', personal);

            if (personal) {
                if (personal.id) {
                    apiAxios.get(`presupuestos/${state.presupuestoId}/personal/${personal.id}`)
                        .then(res => {
                            commit('SET_PRESUPUESTO_PERSONAL', res.data);
                            resolve();
                        })
                        .catch(error => reject(helpers.methods.getErrorMessage(error)));
                }
            }
        })
    },
    inicializarPresupuestoPersonal({ commit }) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_PRESUPUESTO_PERSONAL');
            resolve();
        })
    },
    getPresupuestoPersonal: ({ commit }) => {
        return new Promise((resolve, reject) => {
            if (state.presupuestoId != 0 && state.personalId != 0) {
                apiAxios.get(`presupuestos/${state.presupuestoId}/personal/${state.personalId}`)
                    .then(res => {
                        commit('SET_PRESUPUESTO_PERSONAL', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                commit('INICIALIZAR_PRESUPUESTO_PERSONAL');
                resolve();
            }
        })
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let error;
            let fileName = state.file.name;
            let fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
            let fileSize = parseFloat(state.file.size / 1048576);

            if (fileExtension != 'pdf') {
                error = 'El tipo de archivo seleccionado no es válido. Sólo se permiten documentos PDF.';
            }

            if (fileSize >= 3 ) {
                error = 'El archivo seleccionado tiene un peso mayor al permitido. Sólo se permiten documentos de hasta 3MB.';
            }

            if (state.presupuestoId == 0) {
                error = 'Se produjo un error al seleccionar el presupuesto asociado.';
            }

            if (error) {
                reject(error);
            } else {
                let periodoDocumento = parseInt(state.periodoDocumentoAnio).toString().trim()
                    + '-' + helpers.methods.getMesNumero(state.periodoDocumentoMesNombre).toString().trim()
                    + '-01';

				let formData = new FormData();
                formData.append('file', state.file);
                formData.append('presupuestoId', state.presupuestoId);
                formData.append('personalId', state.personalId);
                formData.append('periodoDocumento', periodoDocumento);
                if (state.observaciones) {
                    if (state.observaciones.trim() != '') {
                        formData.append('observaciones', state.observaciones.trim());
                    }
                }

                let options = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }				

				apiAxios.post('personal-documentos', formData, options)
                    .then(() => resolve())
					.catch(error => reject(helpers.methods.getErrorMessage(error)));
			}
        })
    },
    actualizarEstado ( {}, params ) {
        return new Promise((resolve, reject) => {
            let actualizacionEstado = {
                personalDocumentoEstadoId: params.personalDocumentoEstado.id,
                observaciones: params.observaciones.trim()
            }
            apiAxios.post('personal-documentos/' + params.personalDocumentoId + '/actualizar-estado', actualizacionEstado)
                .then(() => resolve())
				.catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}