import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';

const state = {
    facultadDatos: [],
};

const mutations = {
    updateField,

    'INICIALIZAR_DATOS'(state) {
        state.facultadDatos = [];
    },
    'SET_DATOS'(state, facultadDatos) {
        state.facultadDatos = facultadDatos;
    },
};

const actions = {
    getDatos ( { commit }, facultadId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DATOS');

            if (facultadId) {
                apiAxios.get('facultades/' + facultadId ) 
                    .then(res => {
                        let facultadDatos = [
                            { 'nombre': 'nombre', 'campo': 'Nombre', 'valor': res.data.nombre, 'formato': '' },
                            { 'nombre': 'centroCostos', 'campo': 'Centro de Costos', 'valor': res.data.centroCostos, 'formato': '' },
                            { 'nombre': 'inactivo', 'campo': 'Inactivo', 'valor': res.data.inactivo? 'Si' : 'No', 'formato': '' },
                        ];

                        commit('SET_DATOS', facultadDatos);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })            
    },
};

const getters = {
    getField
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}