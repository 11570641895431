import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    list: [],
    selectList: [],
};

const mutations = {
    updateField,

    'INICIALIZAR_LIST'(state) {
        state.list = [];
        state.selectList = [];
    },
    'SET_LIST'(state, list) {
        state.list = list;
        state.selectList = list.map(item => item.nombre.trim());
    },
};

const actions = {
    getList: ({ commit }, centroCostosId) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_LIST');

            apiAxios.get(`subcentros-costos/centro-costos/${centroCostosId}/list`)
                .then(res => {
                    commit('SET_LIST', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
};

const getters = {
    getField,

    list (state) {
        return state.list;
    },
    selectList (state) {
        return state.selectList;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}