import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    carreras: [],
    /* Paginacion */
    paginaActual: 1,
    registrosPorPagina: 10,
    totalRegistros: 0,
    totalPaginas: 1,
    nombre: '',
    incluirInactivos: false,

    list: [],
    selectList: [],
};

const mutations = {
    updateField,

    'INICIALIZAR'(state) {
        state.carreras = [];
        state.totalRegistros = 0;
    },
    'SET_CARRERAS'(state, carreras) {
        state.carreras = carreras;
        state.totalRegistros = carreras.length;
        if (state.registrosPorPagina != 0) {
            state.totalPaginas = Math.ceil(state.totalRegistros / state.registrosPorPagina);
        } else {
            state.totalPaginas = 1;
        }
    },

    'INICIALIZAR_LIST'(state) {
        state.list = [];
        state.selectList = [];
    },
    'SET_LIST'(state, carreras) {
        state.list = carreras;
        state.selectList = carreras.map(carrera => carrera.nombre.trim());
    },
};

const actions = {
    getCarreras: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            let queryString = '';

            if (state.nombre.trim() != '') {
                queryString += (queryString == ''? '?' : '&') + 'nombre=' + state.nombre.trim();
            }

            if ((/true/i).test(state.incluirInactivos)) {
                queryString += (queryString == ''? '?' : '&') + 'incluirInactivos=true';
            }

            apiAxios.get('carreras' + queryString)
                .then(res => {
                    commit('SET_CARRERAS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
    getList: ({ commit }, facultadId) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_LIST');
            
            let url = 'carreras/list';
            if (facultadId) {
                if (facultadId != 0) {
                    url = 'facultades/' + facultadId + '/carreras/list';
                }
            }
            apiAxios.get(url)
                .then(res => {
                    commit('SET_LIST', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
};

const getters = {
    getField,

    list (state) {
        return state.list;
    },
    selectList (state) {
        return state.selectList;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}