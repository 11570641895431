import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    indicadoresPresupuestos: [],
};

const mutations = {
    updateField,

    'INICIALIZAR_INDICADORES_PRESUPUESTOS'(state) {
        state.indicadoresPresupuestos = [];
    },
    'SET_INDICADORES_PRESUPUESTOS'(state, indicadoresPresupuestos) {
        state.indicadoresPresupuestos = indicadoresPresupuestos;
        
        state.indicadoresPresupuestos = state.indicadoresPresupuestos.sort(function(a, b){
            if (a.nombre < b.nombre) { 
                return -1; 
            }
            if (a.nombre > b.nombre) {
                return 1;
            }
            return 0;
        })
        
        state.indicadoresPresupuestos.forEach(indicadoresFacultad => {
            indicadoresFacultad.indicadoresCarreras = indicadoresFacultad.indicadoresCarreras.sort(function(a, b){
                if (a.nombre < b.nombre) { 
                    return -1; 
                }
                if (a.nombre > b.nombre) {
                    return 1;
                }
                return 0;
            })
        });
    },
};

const actions = {
    getIndicadoresPresupuestos: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_INDICADORES_PRESUPUESTOS');

            apiAxios.get('indicadores/presupuestos')
                .then(res => {
                    commit('SET_INDICADORES_PRESUPUESTOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    }
};

const getters = {
    getField,

    indicadoresPresupuestos (state) {
        return state.indicadoresPresupuestos;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}