import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    personalContratacionDatos: [],
};

const mutations = {
    updateField,

    'INICIALIZAR_DATOS'(state) {
        state.personalContratacionDatos = [];
    },
    'SET_DATOS'(state, personalContratacionDatos) {
        state.personalContratacionDatos = personalContratacionDatos;
    },
};

const actions = {
    getDatos ( { commit }, personalContratacionId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DATOS');

            if (personalContratacionId) {
                apiAxios.get('personal-contrataciones/' + personalContratacionId ) 
                    .then(res => {
                        let personalContratacionDatos = [
                            { 'nombre': 'personal', 'campo': 'Personal', 'valor': res.data.personal, 'formato': '' },
                            { 'nombre': 'carrera', 'campo': 'Carrera', 'valor': res.data.carrera, 'formato': '' },
                            { 'nombre': 'periodo', 'campo': 'Periodo', 'valor': res.data.periodo, 'formato': '' },
                            { 'nombre': 'fechaInicio', 'campo': 'Inicio', 'valor': res.data.fechaInicio, 'formato': 'fecha' },
                            { 'nombre': 'fechaFin', 'campo': 'Finalizacion', 'valor': res.data.fechaFin, 'formato': 'fecha' },
                            { 'nombre': 'observaciones', 'campo': 'Observaciones', 'valor': res.data.observaciones, 'formato': '' },
                        ];

                        commit('SET_DATOS', personalContratacionDatos);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })            
    },
};

const getters = {
    getField
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}