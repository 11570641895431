import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    username: '',
    apellido: '',
    nombre: '',
    rowVersion: null,

    newAvatar: ''
};

const mutations = {
    updateField,

    'INICIALIZAR_USUARIO'(state) {
        state.apellido = '';
        state.nombre = '';
        // state.avatar = '';
        state.username = '';
        state.rowVersion = null;
    },
    'SET_USUARIO'(state, usuario) {
        state.apellido = usuario.apellido.trim();
        state.nombre = usuario.nombre.trim();
        // state.avatar = usuario.avatar.trim();
        state.username = usuario.username.trim();
        state.rowVersion = usuario.rowVersion;
    },
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit } ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_USUARIO');

            apiAxios.get('usuarios/perfil' ) 
                .then(res => {
                    commit('SET_USUARIO', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },    
    save ( { state, commit } ) {
        return new Promise((resolve, reject) => {
            let usuario= {
                nombre: state.nombre.toUpperCase().trim(),
                apellido: state.apellido.toUpperCase().trim(),
                rowVersion: state.rowVersion
            }
            
            let url = 'usuarios/perfil';
            apiAxios.post(url, usuario)
                .then(response => {
                    /* Actualizamos el nombre del usuario logueado */
                    let params = {
                        nombre: state.nombre.toUpperCase().trim(),
                        // avatar: state.avatar.trim()
                    }
                    commit('user/PERFIL', params, { root: true });
                    
                    resolve(response);
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    updatePassword ( {}, params ) {
        return new Promise((resolve, reject) => {
            let pass= {
                password: params.current,
                newPassword: params.pass,
            }
            
            let url = 'usuarios/reset-password';
            apiAxios.post(url, pass)
                .then(response => resolve(response))
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },    
    updateAvatar ({ commit, state }, params ) {
        return new Promise((resolve, reject) => {
            let error;
            let fileName = params.file.name;
            let fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
            let fileSize = parseFloat(params.file.size / 1048576);

            if (fileExtension != 'jpg' 
                & fileExtension != 'png'
                & fileExtension != 'jepg') {
                    error = 'El tipo de archivo seleccionado no es válido. Sólo se permiten imágenes de tipo jpg, png o jpeg.';
            }

            if (fileSize >= 0.2 ) {
                error = 'El archivo seleccionado tiene un peso mayor al permitido. Sólo se permiten imágenes de hasta 200KB.';
            }

            if (error) {
                reject(error);
            } else {
                let formData = new FormData();
                formData.append('avatar', params.file);

                let options = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }

                let url = 'usuarios/' + state.id + '/avatar';
                apiAxios.post(url, formData, options)
                    .then(res => {
                        commit('SET_USUARIO', res.data);
                        
                        let params = {
                            nombre: state.nombre.toUpperCase().trim(),
                            avatar: state.avatar.trim()
                        }
                        commit('user/PERFIL', params, { root: true });
                        
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })
    },    
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}