import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import moment from 'moment';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    nombre: '',
    tipoCarreraId: 0,
    tipoCarrera: null,
    tipoCarreraNombre: null,
    facultadId: 0,
    facultad: null,
    facultadNombre: null,
    centroCostosId: 0,
    centroCostos: '',
    subcentroCostosId: 0,
    subcentroCostos: null,
    subcentroCostosNombre: null,
    inactivo: false,
    rowVersion: null,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, carreraId) {
        state.id = carreraId;
        state.nombre = '';
        state.tipoCarreraId = 0;
        state.tipoCarrera = null;
        state.tipoCarreraNombre = null;
        state.facultadId = 0;
        state.facultad = null;
        state.facultadNombre = null;
        state.centroCostosId = 0;
        state.centroCostos = '';
        state.subcentroCostosId = 0;
        state.subcentroCostos = null;
        state.subcentroCostosNombre = null;
        state.inactivo = false;
        state.rowVersion = null;
    },
    'SET_CARRERA' (state, carrera) {
        state.nombre = carrera.nombre.trim();
        state.tipoCarreraId = carrera.tipoCarrera? carrera.tipoCarrera.id : 0;
        state.tipoCarrera = carrera.tipoCarrera;
        state.tipoCarreraNombre = carrera.tipoCarrera? carrera.tipoCarrera.nombre.trim() : null;
        state.facultadId = carrera.facultad? carrera.facultad.id : 0;
        state.facultad = carrera.facultad;
        state.facultadNombre = carrera.facultad? carrera.facultad.nombre.trim() : null;
        state.centroCostosId = carrera.centroCostosId;
        state.centroCostos = carrera.centroCostos;
        state.subcentroCostosId = carrera.subcentroCostosId;
        state.subcentroCostos = carrera.subcentroCostos;
        state.subcentroCostosNombre = carrera.subcentroCostos? carrera.subcentroCostos.nombre.trim() : null;
        state.inactivo = (/true/i).test(carrera.inactivo);
        state.rowVersion = carrera.rowVersion;
    },
    'SET_TIPO_CARRERA'(state, tipoCarrera) {
        state.tipoCarrera = tipoCarrera;
        if (tipoCarrera) {
            state.tipoCarreraId = tipoCarrera.id? tipoCarrera.id : 0;
            state.tipoCarreraNombre = tipoCarrera.nombre? tipoCarrera.nombre.trim() : null;
        } else {
            state.tipoCarreraId = 0;
            state.tipoCarreraNombre = null;
        }
    },
    'SET_FACULTAD'(state, facultad) {
        state.facultad = facultad;
        if (facultad) {
            state.facultadId = facultad.id? facultad.id : 0;
            state.facultadNombre = facultad.nombre? facultad.nombre.trim() : null;

            state.centroCostosId = facultad.centroCostosId? facultad.centroCostosId : 0;
            state.centroCostos = facultad.centroCostos? facultad.centroCostos.trim() : '';
        } else {
            state.facultadId = 0;
            state.facultadNombre = null;
            state.centroCostosId = 0;
            state.centroCostos = '';
        }
        state.subcentroCostosId = 0;
        state.subcentroCostos = null;
        state.subcentroCostosNombre = null;
    },
    'SET_SUBCENTRO_COSTOS'(state, subcentroCostos) {
        state.subcentroCostos = subcentroCostos;
        if (subcentroCostos) {
            state.subcentroCostosId = subcentroCostos.id? subcentroCostos.id : 0;
            state.subcentroCostosNombre = subcentroCostos.nombre? subcentroCostos.nombre.trim() : null;
        } else {
            state.subcentroCostosId = 0;
            state.subcentroCostosNombre = null;
        }
    },
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, carreraId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', carreraId);

            if (carreraId != 0) {
                apiAxios.get(`carreras/${carreraId}/abm`) 
                    .then(res => {
                        commit('SET_CARRERA', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
            
        })            
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            if (save) {
                let carrera= {
                    nombre: state.nombre.toUpperCase().trim(),
                    tipoCarreraId: state.tipoCarreraId,
                    facultadId: state.facultadId,
                    subcentroCostosId: state.subcentroCostosId,
                    inactivo: (/true/i).test(state.inactivo),
                };

                let url = 'carreras';
                if (state.id != 0) {
                    url += '/' + state.id;
                    carrera.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, carrera)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
    setTipoCarrera: ( { commit }, tipoCarrera) => {
        commit('SET_TIPO_CARRERA', tipoCarrera);
    },
    setFacultad: ( { commit }, facultad) => {
        commit('SET_FACULTAD', facultad);
    },
    setSubcentroCostos: ( { commit }, subcentroCostos) => {
        commit('SET_SUBCENTRO_COSTOS', subcentroCostos);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}