import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import moment from 'moment';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    apellido: '',
    nombre: '',
    documentoNumero: '',
    cuit: '',
    email: '',
    celular: '',
    inactivo: false,
    observaciones: '',
    rowVersion: null,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, personalId) {
        state.id = personalId;
        state.apellido = '';
        state.nombre = '';
        state.documentoNumero = '';
        state.cuit = '';
        state.email = '';
        state.celular = '';
        state.inactivo = false;
        state.observaciones = '';
        state.rowVersion = null;
    },
    'SET_PERSONAL' (state, personal) {
        state.apellido = personal.apellido.trim();
        state.nombre = personal.nombre.trim();
        state.documentoNumero = personal.documentoNumero.trim();
        state.cuit = personal.cuit.trim();
        state.email = personal.email.trim();
        state.celular = personal.celular.trim();
        state.inactivo = (/true/i).test(personal.inactivo);
        state.observaciones = personal.observaciones.trim();
        state.rowVersion = personal.rowVersion;
    },
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, personalId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', personalId);

            if (personalId != 0) {
                apiAxios.get('personal/' + personalId + '/abm' ) 
                    .then(res => {
                        commit('SET_PERSONAL', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
            
        })            
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            if (save) {
                let personal= {
                    apellido: state.apellido.toUpperCase().trim(),
                    nombre: state.nombre.toUpperCase().trim(),
                    documentoNumero: state.documentoNumero.toUpperCase().trim(),
                    cuit: state.cuit.toUpperCase().trim(),
                    email: state.email.trim(),
                    celular: state.celular.toUpperCase().trim(),
                    inactivo: (/true/i).test(state.inactivo),
                    observaciones: state.observaciones.trim(),
                };

                let url = 'personal';
                if (state.id != 0) {
                    url += '/' + state.id;
                    personal.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, personal)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}