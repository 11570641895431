import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    rubros: [],
    nodos: [],
};

const mutations = {
    'INICIALIZAR'(state) {
        state.rubros = [];
        state.nodos = [];
    },
    'SET_RUBROS_CONCEPTOS'(state, rubros) {
        state.rubros = rubros;

        state.rubros.sort(function(a, b){
            if (a.ingresos & !b.ingresos) {
                return -1;
            }
            if (!a.ingresos & b.ingresos) {
                return 1;
            }
            if (parseInt(a.codigo) < parseInt(b.codigo)) { 
                return -1; 
            }
            if (parseInt(a.codigo) > parseInt(b.codigo)) {
                return 1;
            }
            if (a.nombre < b.nombre) { 
                return -1; 
            }
            if (a.nombre > b.nombre) {
                return 1;
            }
            return 0;
        });

        rubros.forEach(rubro => {

            let nodoRubro = {
                id: 'r' + rubro.id.toString().trim(),
                // pId: 0,
                text:  `${rubro.codigo.toString().padStart(2, '0')} ${rubro.nombre.trim()}`,
                data: {
                    rubroId: rubro.id,
                    rubroIngresos: rubro.ingresos,
                    conceptoId: 0,
                    level: 0,
                },
                children: []
            }

            if ((/true/i).test(rubro.inactivo)) {
                nodoRubro.text += ' (Inactivo)'; 
            }

            state.nodos.push(nodoRubro);
    
            if (rubro.conceptos) {
                rubro.conceptos.sort(function(a, b){
                    if (parseInt(a.codigo) < parseInt(b.codigo)) { 
                        return -1; 
                    }
                    if (parseInt(a.codigo) > parseInt(b.codigo)) {
                        return 1;
                    }
                    if (a.nombre < b.nombre) { 
                        return -1; 
                    }
                    if (a.nombre > b.nombre) {
                        return 1;
                    }
                    return 0;
                });

                rubro.conceptos.forEach(concepto => {
                    let nodoConcepto = {
                        id: 'c' + concepto.id.toString().trim(),
                        // pId: 'c' + cuenta.id.toString().trim(),
                        text:  `${concepto.codigo.toString().padStart(2, '0')} ${concepto.nombre.trim()}`,
                        data: {
                            rubroId: rubro.id,
                            rubroIngresos: rubro.ingresos,
                            conceptoId: concepto.id,
                            level: 1,
                        },
                        children: []
                    }

                    if ((/true/i).test(concepto.inactivo)) {
                        nodoConcepto.text += ' (Inactivo)'; 
                    }
                            
                    nodoRubro.children.push(nodoConcepto);
                });
            }
        });
    },
};

const actions = {
    inicializarRubrosConceptos: ( { commit } )  => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            apiAxios.get('rubros/rubros-conceptos')
                .then(res => {
                    commit('SET_RUBROS_CONCEPTOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })            
    },
};

const getters = {
    getField,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}