import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import moment from 'moment';
import helpers from '../../mixins/helpers';

const state = {
    presupuestoId: 0,
    carrera: null,
    periodo: null,

    id: 0,
    personalId: 0,
    personal: null,
    personalNombre: null,
    fechaInicio: moment(new Date()).format('YYYY-MM-DD'),
    fechaFin: moment(new Date()).format('YYYY-MM-DD'),
    observaciones: '',
    rowVersion: null,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, params) {
        state.presupuestoId = params.presupuestoId;
        state.carrera = null;
        state.periodo = null;
    
        state.id = params.personalContratacionId;
        state.personalId = 0;
        state.personal = null;
        state.personalNombre = null;
        state.fechaInicio = moment(new Date()).format('YYYY-MM-DD');
        state.fechaFin = moment(new Date()).format('YYYY-MM-DD');
        state.observaciones = '';
        state.rowVersion = null;
    },
    'SET_PRESUPUESTO'(state, presupuesto) {
        state.carrera = presupuesto.carrera;
        state.periodo = presupuesto.periodo.toString();
    },
    'SET_PERSONAL_CONTRATACION' (state, personalContratacion) {
        state.personalId = personalContratacion.personal? personalContratacion.personal.id : 0;
        state.personal = personalContratacion.personal;
        state.personalNombre = personalContratacion.personal? personalContratacion.personal.nombre.trim() : null;
        state.fechaInicio = moment(personalContratacion.fechaInicio).format('YYYY-MM-DD');
        state.fechaFin = moment(personalContratacion.fechaFin).format('YYYY-MM-DD');
        state.observaciones = personalContratacion.observaciones;
        state.rowVersion = personalContratacion.rowVersion;
    },
    'SET_PERSONAL'(state, personal) {
        state.personal = personal;
        if (personal) {
            state.personalId = personal.id? personal.id : 0;
            state.personalNombre = personal.nombreCompleto? personal.nombreCompleto.trim() : null;
        } else {
            state.personalId = 0;
            state.personalNombre = null;
        }
    },
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, params ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', params);

            if (params.personalContratacionId != 0) {
                apiAxios.get('presupuestos/' + params.presupuestoId) 
                    .then(res => {
                        commit('SET_PRESUPUESTO', res.data);
                        return apiAxios.get('personal-contrataciones/' + params.personalContratacionId + '/abm')
                    })
                    .then(res => {
                        commit('SET_PERSONAL_CONTRATACION', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                apiAxios.get('presupuestos/' + params.presupuestoId) 
                    .then(res => {
                        commit('SET_PRESUPUESTO', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })            
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            if (save) {
                let personalContratacion= {
                    personalId: state.personalId,
                    presupuestoId: state.presupuestoId,
                    fechaInicio: moment(state.fechaInicio).format('YYYY-MM-DD'),
                    fechaFin: moment(state.fechaFin).format('YYYY-MM-DD'),
                    observaciones: state.observaciones.trim(),
                };

                let url = 'personal-contrataciones';
                if (state.id != 0) {
                    url += '/' + state.id;
                    personalContratacion.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, personalContratacion)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
    setPersonal: ({commit}, personal) => {
        commit('SET_PERSONAL', personal);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}