export function getAccesos(userRolId) {
    let accesos = [];
    switch (userRolId) {
        case 1: /* Superadmin */
        case 2: /* Admin */
            break;
        
        case 3: /* Administrador */ 
            accesos = [
                { route: 'dashboard', modulo: '' },
                { route: '', modulo: 'dashboard/indicadores' },

                { route: 'presupuestos', modulo: '' },
                { route: 'presupuestos/add', modulo: '' },
                { route: 'presupuestos/:id/edit', modulo: '' },
                { route: 'presupuestos/:id/duplicar', modulo: '' },
                { route: 'presupuestos/:id', modulo: '' },
                { route: '', modulo: 'presupuestos/delete' },
                { route: '', modulo: 'presupuestos/exportar' },
                { route: 'presupuestos/:presupuestoId/contrataciones', modulo: '' },
                { route: 'presupuestos/:presupuestoId/contrataciones/add', modulo: '' },
                { route: 'presupuestos/:presupuestoId/contrataciones/:id/edit', modulo: '' },
                { route: 'presupuestos/:presupuestoId/contrataciones/:id', modulo: '' },
                { route: '', modulo: 'presupuestos/contrataciones/delete' },
                { route: 'presupuestos/:presupuestoId/documentos', modulo: '' },
                { route: 'presupuestos/:presupuestoId/documentos/add', modulo: '' },
                { route: 'presupuestos/:presupuestoId/documentos/faltantes', modulo: '' },
                // { route: '', modulo: 'presupuestos/documentos/actualizar-estado' },
                { route: '', modulo: 'presupuestos/documentos/delete' },

                { route: 'rr-hh', modulo: '' },
                { route: 'rr-hh/personal', modulo: '' },
                { route: 'rr-hh/personal/add', modulo: '' },
                { route: 'rr-hh/personal/:id/edit', modulo: '' },
                { route: 'rr-hh/personal/:id', modulo: '' },
                { route: 'rr-hh/documentos', modulo: '' },
              ]
              break;

        case 4: /* Supervisor */ 
            accesos = [
                { route: 'dashboard', modulo: '' },
                { route: '', modulo: 'dashboard/indicadores' },

                { route: 'presupuestos', modulo: '' },
                { route: 'presupuestos/add', modulo: '' },
                { route: 'presupuestos/:id/edit', modulo: '' },
                { route: 'presupuestos/:id/duplicar', modulo: '' },
                { route: 'presupuestos/:id', modulo: '' },
                { route: '', modulo: 'presupuestos/delete' },
                { route: '', modulo: 'presupuestos/exportar' },
                { route: '', modulo: 'presupuestos/abrir' },
                { route: '', modulo: 'presupuestos/cerrar' },
                { route: 'presupuestos/:presupuestoId/contrataciones', modulo: '' },
                { route: 'presupuestos/:presupuestoId/contrataciones/add', modulo: '' },
                { route: 'presupuestos/:presupuestoId/contrataciones/:id/edit', modulo: '' },
                { route: 'presupuestos/:presupuestoId/contrataciones/:id', modulo: '' },
                { route: '', modulo: 'presupuestos/contrataciones/delete' },
                { route: 'presupuestos/:presupuestoId/documentos', modulo: '' },
                { route: 'presupuestos/:presupuestoId/documentos/add', modulo: '' },
                { route: 'presupuestos/:presupuestoId/documentos/faltantes', modulo: '' },
                { route: '', modulo: 'presupuestos/documentos/actualizar-estado' },
                { route: '', modulo: 'presupuestos/documentos/delete' },

                { route: 'rr-hh', modulo: '' },
                { route: 'rr-hh/personal', modulo: '' },
                { route: 'rr-hh/personal/add', modulo: '' },
                { route: 'rr-hh/personal/:id/edit', modulo: '' },
                { route: 'rr-hh/personal/:id', modulo: '' },
                { route: 'rr-hh/documentos', modulo: '' },

                { route: 'reportes', modulo: '' },
                { route: 'reportes/presupuestos', modulo: '' },
            ]
            break;
  
        case 5: /* ControlDocumentos */ 
            accesos = [
                { route: 'dashboard', modulo: '' },

                { route: 'presupuestos', modulo: '' },
                { route: 'presupuestos/:id', modulo: '' },
                { route: 'presupuestos/:presupuestoId/contrataciones', modulo: '' },
                { route: 'presupuestos/:presupuestoId/contrataciones/:id', modulo: '' },
                { route: 'presupuestos/:presupuestoId/documentos', modulo: '' },
                { route: 'presupuestos/:presupuestoId/documentos/faltantes', modulo: '' },

                { route: 'rr-hh', modulo: '' },
                { route: 'rr-hh/personal', modulo: '' },
                { route: 'rr-hh/personal/:id', modulo: '' },
                { route: 'rr-hh/documentos', modulo: '' },
            ]
            break;
    }
    
    /* Accesos disponibles para todos los usuarios */
    accesos.push({ route: '/', modulo: '' });
    accesos.push({ route: 'perfil', modulo: '' });
    accesos.push({ route: 'seguridad/usuarios/self-pass', modulo: '' });

    return accesos;
}
