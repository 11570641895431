import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    honorariosHoras: false,

    id: 0,
    personalId: 0,
    personal: '',
    observaciones: '',
    subcantidad: 0,
    cantidad: 0,
    precioUnitario: 0
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, params) {
        state.honorariosHoras = params.honorariosHoras;

        state.personalId = 0;
        state.personal = '';
        state.observaciones = '';
        state.subcantidad = 0;
        state.cantidad = 0;
        state.precioUnitario = 0;
    },
    'SET_RENGLON' (state, renglon) {
        state.personalId = renglon.personalId;
        state.personal = renglon.personal.trim();
        state.observaciones = renglon.observaciones.trim();
        state.subcantidad = parseInt(renglon.subcantidad);
        state.cantidad = parseInt(renglon.cantidad);
        state.precioUnitario = parseFloat(renglon.precioUnitario)
    },
    'SET_PERSONAL'(state, personal) {
        if (personal) {
            state.personalId = personal.id? personal.id : 0;
            state.personal = personal.nombreCompleto? personal.nombreCompleto.trim() : '';
        } else {
            state.personalId = 0;
            state.personal = '';
        }
    },
};

const getters = {
    getField,

    renglon (state) {
        return {
            personalId: state.personalId,
            personal: state.personal.trim(),
            observaciones: state.observaciones.trim(),
            subcantidad: parseInt(state.subcantidad),
            cantidad: parseInt(state.cantidad),
            precioUnitario: parseFloat(state.precioUnitario)
        }
    },
};

const actions = {
    inicializar ( { commit }, params ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', params);

            if (params.renglon) {
                commit('SET_RENGLON', params.renglon);
                resolve();
            } else {
                resolve();
            }
        })            
    },
    save ( { state, commit } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            if (save) {
                let renglon = {
                    personalId: state.personalId,
                    personal: state.personal.toUpperCase().trim(),
                    observaciones: state.observaciones.trim(),
                    subcantidad: parseInt(state.subcantidad),
                    cantidad: parseInt(state.cantidad),
                    precioUnitario: parseFloat(state.precioUnitario)
                }
                commit('SET_RENGLON', renglon);
                resolve();
            }

            reject(errorMessage);
        })
    },
    setPersonal: ({commit}, personal) => {
        commit('SET_PERSONAL', personal);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}